import { ACTIONS, MUTATIONS } from "./names";
import { DEFAULT_ADDRESS } from "~/constants/constants";
import { MetaWorldManager } from "~/core/services/map/MetaWorldManager";
import { MetaUser } from "~/core/models/MetaUser";
import { PaymentType } from "~/core/models/purchase-popup-2/PaymentType";
import { METAVERSE_IDS, METAVERSES } from "~/core/services/utils/MetaverseConstants";
import { fromWei } from "~/core/helpers/GlobalHelpers";
import {
    affilityBundlesInfoData,
    btcBundlesInfoData,
    btcSingleProductsInfoData,
    getParcelInfoContractByMetaverseId,
} from "~/store/affility/pages/shop/bundleConfig";
import { ParcelStats } from "~/store/affility/pages/shop/types";
import { NULLABLE_WALLET_ADDRESS } from "~/core/services/utils/Constants";

const bundlesNullableWallet = '0xB865188207b32c2fCa73C29BA546d5d00c432fe2';

function processBundlesData(bundlesData: any[], bundleInfo: any, splitPrices?: any, nftTurboData?: any) {
    let bundles = [];
    if (bundlesData) {
        for (let i = 0; i < bundlesData.length; i++) {
            const bnd = bundlesData[i];
            const bundleData = {
                name: bundleInfo[bnd[0]].name,
                sc_id: parseInt(bnd[0]),
                number_of_parcels: parseInt(bnd[1]),
                discounted_price: fromWei(bnd[4]),
                price: fromWei(bnd[5]),
                commission_volume: bnd[6] ? fromWei(bnd[6]) : 0,
                benefitsInfo: bundleInfo[bnd[0]].benefitsInfo,
                splitPrices: splitPrices ? splitPrices.get(bundleInfo[bnd[0]].typeID) : null,
                terahashValue: bundleInfo[bnd[0]]?.terahashValue,
            };

            if(nftTurboData) {
                const turboPrice = nftTurboData?.get(bundleData.sc_id)?.usdt;
                if (turboPrice) {
                    bundleData.discounted_price = turboPrice;
                }
            }

            const position = bundleInfo[bnd[0]]?.preservedIndex;
            if (typeof position === 'number') {
                bundles[position] = bundleData;
            } else {
                bundles.push(bundleData);
            }
        }
        bundles = bundles.filter((val) => val);
    }
    return bundles;
}

export default {
    async [ACTIONS.GET_BUNDLES_DATA]({ commit, dispatch }, user: MetaUser) {
        const municipalityContract = MetaWorldManager.sharedInstance().contracts.Municipality;
        const handleGetBundles = municipalityContract.methods.getAffilitySuperBundles;
        const handleGetStarterBundle = municipalityContract.methods.getStarterBundle;

        try {
            const response = await Promise.all([
                handleGetStarterBundle().call(),
                handleGetBundles(bundlesNullableWallet).call()
            ])
            let nftTurbo: any;
            const bundlesData = [response[0], ...response[1]];
            const bundleInfo = affilityBundlesInfoData();

            if(user?.isNFTTurboAvailable) {
                nftTurbo = await dispatch(ACTIONS.GET_BUNDLES_SPLIT_PRICES, {
                    bundles: bundleInfo,
                    contract: municipalityContract,
                    walletAddress: user ? user.walletAddress : DEFAULT_ADDRESS,
                    type: PaymentType.REGULAR,
                })
            }

            const splitPrices = await dispatch(ACTIONS.GET_BUNDLES_SPLIT_PRICES, {
                bundles: bundleInfo,
                contract: municipalityContract,
                walletAddress: user ? user.walletAddress : DEFAULT_ADDRESS
            })

            const bundles = processBundlesData(bundlesData, bundleInfo, splitPrices, nftTurbo);

            commit(MUTATIONS.SET_BUNDLES_DATA, bundles);
        } catch (e) {
            console.error(e);
        } finally {
            commit(MUTATIONS.SET_BUNDLES_DATA_LOADING, false);
        }
    },
    async [ACTIONS.ON_BTC_BUNDLES_DATA]({ commit, dispatch }, user: MetaUser) {
        const municipalityContract = MetaWorldManager.sharedInstance().contracts.Municipality;
        const handleGetBundles = municipalityContract.methods.getBTCSuperBundles;

        try {
            const bundlesData = await handleGetBundles(bundlesNullableWallet).call();

            const bundleInfo = btcBundlesInfoData();

            const splitPrices = await dispatch(ACTIONS.GET_BUNDLES_SPLIT_PRICES, {
                bundles: bundleInfo,
                contract: municipalityContract,
                walletAddress: user ? user.walletAddress : DEFAULT_ADDRESS
            })

            const bundles = processBundlesData(bundlesData, bundleInfo, splitPrices);

            commit(MUTATIONS.SET_BTC_BUNDLES_DATA, bundles);
        } catch (e) {
            console.log(e);
        } finally {
            commit(MUTATIONS.SET_BTC_BUNDLES_DATA_LOADING, false);
        }
    },
    [ACTIONS.GET_BUNDLES_SPLIT_PRICES]: async function ({ }, { bundles, contract, walletAddress, type = PaymentType.GYMNET }) {
        try {
            const splitPricesBundlePromises = Object.values(bundles)
                .filter(({ isSuper }) => isSuper)
                .map(async ({ typeID }: any) => {
                    const price = await contract.methods
                        .getPriceForSuperBundle(typeID, walletAddress, type)
                        .call();
                    return { typeID, price };
                });

            const splitPricesWei = await Promise.all(splitPricesBundlePromises);

            const splitPrices = new Map<number, { usdt: number; gymnet: number; commission_volume: number }>();
            splitPricesWei.forEach(({ typeID, price }) => {
                splitPrices.set(typeID, {
                    usdt: Number(fromWei(price[0])),
                    gymnet: Number(fromWei(price[2])),
                    commission_volume: Number(fromWei(price[3])),
                });
            });

            return splitPrices;
        } catch (error) {
            console.error('Error fetching bundle prices:', error);
            throw error;
        }
    },
    [ACTIONS.ON_BUNDLES_DATA_LOADING]({ commit }, value: boolean) {
        commit(MUTATIONS.SET_BUNDLES_DATA_LOADING, value);
    },
    async [ACTIONS.GET_PARCELS_SLOTS_DATA]({ commit, rootGetters }) {
        const municipalityContract = MetaWorldManager.sharedInstance().contracts.Municipality;
        const handleGetSoledParcels = municipalityContract.methods.currentlySoldStandardParcelsCount().call;
        const metaverseId = rootGetters['map/mapModule/metaverseId'];

        try {
            let parcelStats: ParcelStats;
            const soldParcelsCount = await handleGetSoledParcels();
            const priceInfo = await MetaWorldManager.sharedInstance().fetchParcelPriceInfo(1);
            const soldCount = Number(soldParcelsCount);
            const metaverse = METAVERSES[metaverseId];
            const soldPercentage = 100 * soldCount / metaverse.standard_parcels_count;
            parcelStats = {
                soldParcelsCount: soldCount,
                parcelsCurrentPrice: priceInfo.priceForBuyParcel,
                percentages: [10, 25, 50, 75, 100],
                prices: [100, 116, 144, 209, 301],
                standardParcelsTotalCount: metaverse.standard_parcels_count,
                soldPercentage,
                leftParcelsCount: metaverse.standard_parcels_count - soldParcelsCount,
                nextPriceChanges: 0,
                nextPercentage: 0,
                soldPercentageOptional: {
                    full: soldPercentage.toFixed(3),
                    integer: soldPercentage.toFixed(3).split('.')[0],
                    decimal: soldPercentage.toFixed(3).split('.')[1],
                },
            };
            const nextPercentage = findClosest(parcelStats.percentages, parcelStats.soldPercentage);
            parcelStats.nextPriceChanges = ((parcelStats.standardParcelsTotalCount / 100) * nextPercentage) - soldParcelsCount;
            parcelStats.nextPercentage = nextPercentage;

            commit(MUTATIONS.SET_PARCELS_SLOTS_DATA, parcelStats);
        } catch (e) {
            console.error(e);
        } finally {
            commit(MUTATIONS.SET_PARCELS_SLOTS_DATA_LOADING, false);
        }
    },
    [ACTIONS.ON_PARCELS_SLOTS_DATA_LOADING]({ commit }, value: boolean) {
        commit(MUTATIONS.SET_PARCELS_SLOTS_DATA_LOADING, value);
    },
    async [ACTIONS.GET_PARCEL_PRICE_INFO](
        {},
        { count = 1, type = PaymentType.REGULAR } = {}
    ) {
        try {
            const municipalityContract = MetaWorldManager.sharedInstance().contracts.Municipality;
            const getPriceForPurchaseParcels = municipalityContract.methods.getPriceForPurchaseParcels;

            return await getPriceForPurchaseParcels(count, type).call();
        } catch (error) {
            console.error('Failed to fetch parcel price info:', error);
            throw error;
        }
    },
    async [ACTIONS.ON_SINGLE_PARCEL_DATA]({ commit, dispatch }) {
        try {
            const [price, splitPrices]: [any, any] = await Promise.all([
                dispatch(ACTIONS.GET_PARCEL_PRICE_INFO, { count: 1, type: PaymentType.REGULAR }),
                dispatch(ACTIONS.GET_PARCEL_PRICE_INFO, { count: 1, type: PaymentType.GYMNET }),
            ]);

            const parcelInfo = {
                name: 'Standard Parcel',
                number_of_parcels: 1,
                discounted_price: Number(fromWei(price[1])),
                price: null,
                splitPrices: {
                    usdt: Number(fromWei(splitPrices[0])),
                    gymnet: Number(fromWei(splitPrices[2])),
                },
            }

            commit(MUTATIONS.SET_SINGLE_PARCEL_DATA, parcelInfo);
        } catch (e) {
            console.log(e);
        } finally {
            commit(MUTATIONS.SET_SINGLE_PARCEL_DATA_LOADING, false);
        }
    },
    [ACTIONS.ON_SINGLE_PARCEL_DATA_LOADING]({ commit }, value: boolean) {
        commit(MUTATIONS.SET_SINGLE_PARCEL_DATA_LOADING, value);
    },
    async [ACTIONS.ON_CARDS_BUNDLES_DATA]({ commit }, user: MetaUser) {
        const cardsContract = MetaWorldManager.sharedInstance().contracts.CashFT;

        try {
            const walletAddress = user?.walletAddress || NULLABLE_WALLET_ADDRESS;
            const getCardsBundles = await cardsContract.methods.getInfo(walletAddress).call();
            commit('cashFT/products/SET_USER_KYC_STATUS_FROM_CONTRACT', getCardsBundles.userKyc, { root: true });

            const cardBundles = Object.keys(getCardsBundles)
                .filter((key) => isNaN(Number(key)) && typeof getCardsBundles[key] === 'object')
                .map((key) => {
                    const bundleData = getCardsBundles[key];
                    return {
                        bundle_sc_id: Number(bundleData[0]),
                        number_of_parcels: Number(bundleData[1]),
                        price: Number(this.$readWeb3().utils.fromWei(bundleData[2])),
                        total_purchased: Number(bundleData[3]),
                        is_active: Boolean(Number(bundleData[4])),
                        minimum_gymnet_deposit: Number(this.$readWeb3().utils.fromWei(bundleData[5])),
                    };
                });

            commit(MUTATIONS.SET_CARDS_BUNDLES_DATA, cardBundles);
        } catch (e) {
            console.log(e);
        } finally {
            commit(MUTATIONS.SET_CARDS_BUNDLES_DATA_LOADING, false);
        }
    },
    [ACTIONS.ON_CARDS_BUNDLES_DATA_LOADING]({ commit }, value: boolean) {
        commit(MUTATIONS.SET_CARDS_BUNDLES_DATA_LOADING, value);
    },
    [ACTIONS.ON_PARCEL_BUY_POPUP_VISIBLE]({ commit }, value: boolean) {
        commit(MUTATIONS.SET_PARCEL_BUY_POPUP_VISIBLE, value);
    },
    async [ACTIONS.ON_BTC_SINGLE_PRODUCT_DATA]({ commit }) {
        const municipalityContract = MetaWorldManager.sharedInstance().contracts.Municipality;
        const handleGetBundles = municipalityContract.methods.getSingleProducts;

        try {
            const bundlesData = await handleGetBundles().call();

            const bundleInfo = btcSingleProductsInfoData();

            const bundles = processBundlesData(bundlesData, bundleInfo);

            commit(MUTATIONS.SET_BTC_SINGLE_PRODUCT_DATA, bundles);
        } catch (e) {
            console.log(e)
        } finally {
            commit(MUTATIONS.SET_BTC_SINGLE_PRODUCT_DATA_LOADING, false);
        }
    },
    async [ACTIONS.ON_BTC_SINGLE_PRODUCT_ACTIVE]({ commit }, user: MetaUser) {
        const affilityContractMethod = getParcelInfoContractByMetaverseId(METAVERSE_IDS.GYMSTREET).methods.parcelInf;
        const kabutochoContractMethod = getParcelInfoContractByMetaverseId(METAVERSE_IDS.KABUTOCHO).methods.parcelInf;
        const walletAddress = user ? user.walletAddress : '';

        if (!walletAddress) {
            commit(MUTATIONS.SET_BTC_SINGLE_PRODUCT_ACTIVE, false);
            return;
        }

        try {
            const response = await Promise.all([
                affilityContractMethod(walletAddress).call(),
                kabutochoContractMethod(walletAddress).call()
            ]);

            const affilityCount = parseFloat(response[0]?.parcelCount);
            const kabutochoCount = parseFloat(response[1]?.parcelCount);

            const isActive = affilityCount > 0 || kabutochoCount > 0;

            commit(MUTATIONS.SET_BTC_SINGLE_PRODUCT_ACTIVE, isActive);
        } catch (e) {
            console.log(e);
        }
    },
    [ACTIONS.ON_BTC_SINGLE_PRODUCT_DATA_LOADING]({ commit }, value: boolean) {
        commit(MUTATIONS.SET_PARCEL_BUY_POPUP_VISIBLE, value);
    },
    [ACTIONS.ON_CLEAR_SHOP_DATA]({ commit }) {
        commit(MUTATIONS.SET_BUNDLES_DATA, []);
        commit(MUTATIONS.SET_BUNDLES_DATA_LOADING, true);
        commit(MUTATIONS.SET_PARCELS_SLOTS_DATA, null);
        commit(MUTATIONS.SET_PARCELS_SLOTS_DATA_LOADING, true);
        commit(MUTATIONS.SET_SINGLE_PARCEL_DATA, null);
        commit(MUTATIONS.SET_SINGLE_PARCEL_DATA_LOADING, true);
        commit(MUTATIONS.SET_CARDS_BUNDLES_DATA, []);
        commit(MUTATIONS.SET_CARDS_BUNDLES_DATA_LOADING, true);
        commit(MUTATIONS.SET_BTC_BUNDLES_DATA, []);
        commit(MUTATIONS.SET_BTC_BUNDLES_DATA_LOADING, true);
        commit(MUTATIONS.SET_BTC_SINGLE_PRODUCT_DATA, []);
        commit(MUTATIONS.SET_BTC_SINGLE_PRODUCT_DATA_LOADING, true);
    },
}

const findClosest = (arr, num) => {
    if (arr == null) return;
    let goal = 0;

    for (const [index, el] of arr.entries()) {
        if (num <= el) {
            goal = arr[index];
            break;
        }
    }

    return goal;
};
