var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ComposedPopupNoCloseButtonAffility',{attrs:{"visible":_setup.internalVisible,"closeOnClickModal":!_setup.isConfirmationRequired,"closeOnPressEscape":!_setup.isConfirmationRequired,"width":'min(412px, calc(100vw - 32px))',"title":_vm.$t('email_verification_required'),"description":_vm.$t('email_verification_confirm')},on:{"update:visible":function($event){_setup.internalVisible=$event}}},[_c('div',{staticClass:"email-confirmation-popup-content"},[_c('BaseCardSimpleRounded',{staticClass:"email-confirmation-countdown d-flex justify-content-center align-items-center",class:{
                'error': _setup.generalError,
            },nativeOn:{"click":function($event){!_setup.buttonActionInProgress && _setup.generalError && (_setup.generalError = '')}}},[(_setup.buttonActionInProgress)?_c('BaseLoaderAffility',{attrs:{"isXmasMode":_vm.$config.xmasMode,"duration":"1s"}}):(_setup.generalError)?_c('p',[_vm._v("\n                "+_vm._s(_vm.$t(_setup.generalError))+"\n            ")]):_c('BaseCountdownSimple',{attrs:{"time":_setup.emailConfirmationExpiration,"closeTime":30 * 60 * 1000}})],1),_vm._v(" "),_c('form',{staticClass:"change-email-form align-self-stretch d-flex flex-column align-items-stretch",on:{"submit":function($event){$event.preventDefault();return _setup.changeEmail({ email: _setup.email, mutateUser: true, })}}},[_c('ComposedFormItemInput',{staticClass:"email-input",attrs:{"name":"email","type":"email","label":_vm.$t('new_email'),"placeholder":_vm.$t('enter_new_email'),"inputAttrs":{ type: 'email' },"size":"m","error":_vm.$t(_setup.emailError),"disabled":_setup.buttonActionInProgress,"required":""},model:{value:(_setup.email),callback:function ($$v) {_setup.email=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"email"}}),_vm._v(" "),_c('BaseButtonSimple',{attrs:{"type":"tonal","styling":"tertiary","size":_setup.buttonSize,"disabled":!_setup.email || !!_setup.emailError || _setup.buttonActionInProgress || _setup.lastSentRecently}},[_vm._v("\n                "+_vm._s(_vm.$t('change_email'))+"\n                "),(_setup.lastSentRecently)?_c('BaseCountdownCompact',{attrs:{"units":[
                        {
                            slotProp: 'seconds',
                        },
                    ],"time":_setup.lastEmailDate + _setup.BACK_END_EMAIL_INTERVAL},on:{"end":function($event){_setup.lastSentRecently = false}}}):_vm._e()],1)],1),_vm._v(" "),_c('div',{staticClass:"popup-actions align-self-stretch d-flex flex-column align-items-stretch"},[_c('p',{staticClass:"or-text"},[_vm._v("\n                "+_vm._s(_vm.$t('or'))+"\n            ")]),_vm._v(" "),_c('BaseButtonSimple',{attrs:{"type":"cta","styling":"primary","size":_setup.buttonSize,"disabled":_setup.buttonActionInProgress || _setup.lastSentRecently},nativeOn:{"click":function($event){return _setup.resendEmail.apply(null, arguments)}}},[_vm._v("\n                "+_vm._s(_vm.$t('resend_email'))+"\n                "),(_setup.lastSentRecently)?_c('BaseCountdownCompact',{attrs:{"units":[
                        {
                            slotProp: 'seconds',
                        },
                    ],"time":_setup.lastEmailDate + _setup.BACK_END_EMAIL_INTERVAL},on:{"end":function($event){_setup.lastSentRecently = false}}}):_vm._e()],1),_vm._v(" "),_c('BaseButtonSimple',{staticClass:"cancel-button",attrs:{"type":"outline","styling":"tertiary","size":_setup.buttonSize},nativeOn:{"click":function($event){return _setup.cancelVerification({ logoutIfNecessary: true })}}},[_vm._v("\n                "+_vm._s(_setup.isConfirmationRequired ? _vm.$t('log_out') : _vm.$t('cancel'))+"\n            ")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }