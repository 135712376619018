import { MetaUser } from "~/core/models/MetaUser";
import { createEventName, GOOGLE_ANALYTICS_EVENT_NAMES, GOOGLE_ANALYTICS_EVENT_TYPES } from "~/constants/gtag-events";
import { GlobalMakerService } from "~/core/services/GlobalMakerService";
import { getClientRuntimeConfig } from "~/core/helpers/GlobalHelpers";
import { ACTIONS as TOAST_ACTIONS } from "~/store/affility/user/feedback/modals/toast-notification/names";
const $config = getClientRuntimeConfig();

export function createCanBuyForWeb3MintParcels(authUser: MetaUser, isCalledFromMap: boolean) {
    return (forMeOrAddress: any) => {
        if (typeof forMeOrAddress === 'boolean' && forMeOrAddress ||
            typeof forMeOrAddress === 'string' && forMeOrAddress.toLowerCase() === authUser.walletAddress.toLowerCase()) {
            return true;
        }
        return !isCalledFromMap || forMeOrAddress.toLowerCase?.() === authUser.walletAddress.toLowerCase();
    };
}

export function createCanBuyForWeb3PurchaseStarterBundle(authUser: MetaUser, contract: any) {
    return async (forMeOrAddress: any) => {
        if (typeof forMeOrAddress === 'boolean' && forMeOrAddress) {
            const result = await contract.methods.starterBundleCheck(authUser.walletAddress).call();
            return !result;
        }
        if (typeof forMeOrAddress === 'boolean' && !forMeOrAddress) {
            return true;
        }
        const result = await contract.methods.starterBundleCheck(forMeOrAddress).call();
        if (result) {
            throw new Error('The user already owns this bundle. Please choose a different bundle to proceed.');
        }
        return !result;
    };
}

export function createCanBuyForWeb3PurchaseCardBundle(authUser: MetaUser) {
    return async (forMeOrAddress: any, fromAddress: any) => {
        if (typeof forMeOrAddress === 'boolean' && forMeOrAddress) {
            return true;
        }

        if (typeof forMeOrAddress === 'boolean' && !forMeOrAddress) {
            return false;
        }

        const isBuyerWalletIdenticalToCurrentUsers = fromAddress.toLowerCase() === authUser.walletAddress.toLowerCase();

        if (!isBuyerWalletIdenticalToCurrentUsers) {
            throw new Error('purchase_with_wallet_connected_to_account');
        }

        return isBuyerWalletIdenticalToCurrentUsers &&
            (!forMeOrAddress || authUser.walletAddress.toLowerCase() === forMeOrAddress.toLowerCase());
    };
}

export function methodsToSendEvent() {
    return [
        'web3MintParcels',
        'web3PurchaseSuperBundle',
        'web3PurchaseStarterBundle',
        'web3PurchaseCardBundle',
        'purchaseSingleProduct',
    ]
}

export function sendEventToAnalytics (
    method: string,
    data: any,
    GAEventType = GOOGLE_ANALYTICS_EVENT_TYPES.SUCCESS_TRANSACTION,
    purchaseNameModifier = GOOGLE_ANALYTICS_EVENT_TYPES.SUCCESS,
    productQuantity = 1
) {
    let eventName = null;
    const purchase_name = [ `(${purchaseNameModifier})`, data.purchaseName].join(' ');
    const purchase_price = Number(data.price) / productQuantity;

    switch(method) {
        case 'web3MintParcels':
            eventName = createEventName(GOOGLE_ANALYTICS_EVENT_NAMES.BUY_PARCEL, GAEventType);
            break;
        case 'web3MintMiners':
            eventName = createEventName(GOOGLE_ANALYTICS_EVENT_NAMES.BUY_MINER, GAEventType);
            break;
        case 'web3PurchaseBasicBundle':
        case 'web3PurchaseParcelsBundle':
        case 'web3PurchaseMinersBundle':
            eventName = createEventName(GOOGLE_ANALYTICS_EVENT_NAMES.BUY_BUNDLE, GAEventType);
            break;
        case 'web3PurchaseSuperBundle':
            eventName = createEventName(GOOGLE_ANALYTICS_EVENT_NAMES.BUY_SUPER_BUNDLE, GAEventType);
            break;
        case 'web3PurchaseStarterBundle':
            eventName = createEventName(GOOGLE_ANALYTICS_EVENT_NAMES.BUY_STARTER_BUNDLE, GAEventType);
            break;
    }
    if(eventName) {
        let transaction_id = `P-${String(new Date().valueOf())}`;
        let eventData = {
            transaction_id,
            value: Number(data.price),
            purchase_name: data.purchaseName,
            currency: "USD",
            items: [{
                item_name: data.purchaseName,
                quantity: productQuantity,
                price: purchase_price
            }]
        };
        try {
            if (purchaseNameModifier === GOOGLE_ANALYTICS_EVENT_TYPES.SUCCESS) {
                GlobalMakerService.$gtag.event(GOOGLE_ANALYTICS_EVENT_NAMES.PURCHASE, eventData);
            }
            GlobalMakerService.$gtag.event(eventName, {
                transaction_id,
                purchase_name,
                purchase_price: Number(data.price),
            });
        } catch(e) {
            console.warn('>>>>>>>>>>>> Probably no $gtag found <<<<<<<<<<<<<<<', e);
        }
    } else {
        console.warn('No municipality event found for method: ', method);
    }
}

export function handleBuyDisabled({ dispatch }) {
    if ($config.isBuyDisabled) {
        dispatch(
            `affility/user/feedback/modals/toast-notification/${TOAST_ACTIONS.SHOW_ERROR_TOAST}`,
            {
                description: 'Purchases are disabled, please wait for them to be enabled again.',
            },
            { root: true }
        );
        return true; // Indicate that the purchase flow should stop
    }
    return false; // Indicate that the purchase flow can continue
}
