import { ACTIONS, MUTATIONS } from "./names";
import { PURCHASE_STEPS } from "~/core/types/purchase-popup-2/PurchaseSteps";
import {
    handleBuyDisabled,
    methodsToSendEvent,
    sendEventToAnalytics,
    createCanBuyForWeb3MintParcels,
    createCanBuyForWeb3PurchaseCardBundle,
    createCanBuyForWeb3PurchaseStarterBundle,
} from "~/store/affility/purchase/helpers";
import { MetaWorldManager } from "~/core/services/map/MetaWorldManager";
import { GOOGLE_ANALYTICS_EVENT_TYPES } from "~/constants/gtag-events";
import { FinishReasonEnum } from "~/core/types/purchase-popup-2/FinishReasonEnum";
import { PriceFilterTypes } from "~/pages/shop/types/PriceFilterTypes";
import { GlobalMakerService } from "~/core/services/GlobalMakerService";
import { PaymentType } from "~/core/models/purchase-popup-2/PaymentType";
import { PRODUCT_TYPES } from "~/core/services/utils/Constants";

export default {
    async [ACTIONS.ON_APPROVE_MUNICIPALITY_TRANSACTION]({ rootGetters, dispatch }, { txData, isCalledFromMap = false }) {
        const {
            price,
            method,
            params,
            purchaseName,
            product_type,
            quantity,
            bundle_sc_id,
            bundleData,
            selectedToken,
            benefitsCommissionRank
        } = txData;
        let infoTexts: any;
        let canBuyFor: any;
        let response: any;
        const contract = 'Municipality';
        const authUser = rootGetters['application/driver/authUser'];
        const municipalityContract = MetaWorldManager.sharedInstance().contracts.Municipality;

        switch (method) {
            case 'web3MintParcels': {
                canBuyFor = createCanBuyForWeb3MintParcels(authUser, isCalledFromMap);
                break;
            }
            case 'web3PurchaseStarterBundle': {
                canBuyFor = createCanBuyForWeb3PurchaseStarterBundle(authUser, municipalityContract);
                break;
            }
            case 'web3PurchaseCardBundle':
            case 'purchaseSingleProduct': {
                canBuyFor = createCanBuyForWeb3PurchaseCardBundle(authUser);
                infoTexts = [
                    {
                        text: 'product_not_for_others_purchase_for_yourself',
                        step: PURCHASE_STEPS.SELECT_PRODUCT_OWNER,
                    },
                ];
                break;
            }
        }

        response = await dispatch(ACTIONS.ON_START_PURCHASE, {
            purchaseName,
            price,
            method,
            params,
            product_type,
            quantity,
            bundle_sc_id,
            bundleData,
            canBuyFor,
            infoTexts,
            contract,
            selectedToken,
            benefitsCommissionRank,
            isCalledFromMap,
        });

        if (
            !authUser.isUsedFastStartIncentive &&
            new Date() < new Date(authUser.fastStartIncentiveDate) &&
            Number(price || 0) + Number(authUser.totalOwnPurchaseAmount || 0) >= 100
        ) {
            dispatch('user/subscription/updateUserSubscriptionExpires', null, { root: true });
            dispatch('user/subscription/updateFrozenAutoship', null, { root: true });
        }

        dispatch(ACTIONS.ON_SEND_EVENTS, { method, quantity, response, txData });

        return response;
    },
    [ACTIONS.ON_SEND_EVENTS]({ rootState, dispatch }, { method, quantity, response, txData }) {
        if (methodsToSendEvent().includes(method)) {
            const productQuantity = quantity || 1;
            let eventType = GOOGLE_ANALYTICS_EVENT_TYPES.SUCCESS_TRANSACTION;
            let eventNameModifier = GOOGLE_ANALYTICS_EVENT_TYPES.SUCCESS;
            const abandonmentStep = rootState.application['purchase-control'].finishStep;

            if (response === FinishReasonEnum.FAILED) {
                eventType = GOOGLE_ANALYTICS_EVENT_TYPES.FAIL_TRANSACTION;
                eventNameModifier = GOOGLE_ANALYTICS_EVENT_TYPES.FAILED + ` at step ${abandonmentStep}`;
            } else if (response === FinishReasonEnum.CLOSED_BY_USER) {
                eventType = GOOGLE_ANALYTICS_EVENT_TYPES.USER_CANCEL;
                dispatch('application/popup-control/showWeb2Popup', false, { root: true });
                eventNameModifier = GOOGLE_ANALYTICS_EVENT_TYPES.CANCEL + ` at step ${abandonmentStep}`;
            }

            sendEventToAnalytics(method, txData, eventType, eventNameModifier, productQuantity)
        }
    },
    [ACTIONS.ON_START_PURCHASE]({ dispatch }, {
        purchaseName,
        price,
        method,
        params,
        product_type,
        quantity,
        bundle_sc_id,
        bundleData = {},
        canBuyFor,
        infoTexts,
        contract,
        selectedToken = PriceFilterTypes.USDT,
        benefitsCommissionRank,
        isCalledFromMap,
    }) {
        dispatch('application/purchase-control/show', {
            price,
            method,
            params,
            purchaseName,
            product_type,
            quantity,
            bundle_sc_id,
            bundleData,
            canBuyFor,
            infoTexts,
            contract,
            selectedToken,
            benefitsCommissionRank,
            isCalledFromMap,
        }, { root: true });

        return new Promise((resolve) => {
            // make watcher that wait isFinished and return to flow
            const unwatch = GlobalMakerService.$store.watch(
                (_, getters) => {
                    return getters['application/purchase-control/isFinished'];
                },
                () => {
                    unwatch();
                    const finishReason = GlobalMakerService.$store.state.application['purchase-control'].finishReason;
                    resolve(finishReason);
                },
                {
                    deep: true,
                },
            );
        });
    },

    // Purchase Parcel
    async [ACTIONS.ON_PURCHASE_PARCEL]({ dispatch, rootGetters }, { parcelData, isCalledFromMap = false }) {
        if (handleBuyDisabled({ dispatch })) return;

        const authUser = rootGetters['application/driver/authUser'];
        const referralId = await authUser.getReferrerCode();
        const walletAddress = authUser.walletAddress ?? authUser.relatedWalletAddress;
        const paymentTypeMethod = PaymentType.REGULAR;

        return await dispatch(ACTIONS.ON_APPROVE_MUNICIPALITY_TRANSACTION, {
            txData: {
                price: parcelData.price,
                purchaseName: 'Parcels',
                method: 'web3MintParcels',
                params: [parcelData.count, referralId, walletAddress, paymentTypeMethod, false],
                product_type: PRODUCT_TYPES.PARCEL,
                quantity: parcelData.count,
                bundleData: {
                    image: require('@/assets/affility/images/product/affility/parcels/parcel.webp'),
                },
                selectedToken: parcelData.selectedToken ? parcelData.selectedToken : PriceFilterTypes.USDT
            },
            isCalledFromMap
        })
    },

    // Purchase Super Bundle
    async [ACTIONS.ON_PURCHASE_SUPER_BUNDLE]({ dispatch, rootGetters }, { payload, selectedToken = PriceFilterTypes.USDT }) {
        if (handleBuyDisabled({ dispatch })) return;

        const methodName = (() => {
            if (payload.isStarterBundle()) return 'web3PurchaseStarterBundle';
            if (payload.isSuperBundle()) return 'web3PurchaseSuperBundle';
            if (payload.isCardBundle()) return 'web3PurchaseCardBundle';
            throw new Error(`Cannot determine contract method to call`);
        })();

        const authUser = rootGetters['application/driver/authUser'];
        const referralId = await authUser.getReferrerCode();
        const walletAddress = authUser.walletAddress ?? authUser.relatedWalletAddress;
        const paymentTypeMethod = PaymentType.REGULAR;

        const params = (() => {
            if (payload.isCardBundle()) {
                return [payload.smartContractId, referralId, walletAddress, false];
            }
            if (payload.isStarterBundle()) {
                return [referralId, walletAddress, false];
            }
            return [payload.smartContractId, referralId, walletAddress, paymentTypeMethod, false];
        })();

        return await dispatch(ACTIONS.ON_APPROVE_MUNICIPALITY_TRANSACTION, {
            txData: {
                purchaseName: payload.name,
                price: payload.discountedPrice,
                method: methodName,
                params,
                product_type: PRODUCT_TYPES.BUNDLE,
                bundle_sc_id: payload.smartContractId,
                bundleData: {
                    numberOfParcels: payload.numberOfParcels,
                    image: payload.image,
                },
                selectedToken,
                benefitsCommissionRank: payload.benefitsCommissionRank,
            }
        });
    },

    // Purchase BTC Single Product
    async [ACTIONS.ON_PURCHASE_BTC_SINGLE_PRODUCT]({ dispatch }, { payload }) {
        if (handleBuyDisabled({ dispatch })) return;

        return await dispatch(ACTIONS.ON_APPROVE_MUNICIPALITY_TRANSACTION, {
            txData: {
                purchaseName: payload.name,
                price: payload.discountedPrice,
                method: 'purchaseSingleProduct',
                params: [payload.smartContractId, false],
                product_type: PRODUCT_TYPES.BUNDLE,
                bundle_sc_id: payload.smartContractId,
                bundleData: {
                    image: payload.image,
                },
                selectedToken: PriceFilterTypes.USDT,
                benefitsCommissionRank: payload.benefitsCommissionRank,
            }
        });
    },

    // Purchase Product Autoship
    async [ACTIONS.ON_PURCHASE_AUTOSHIP]({ dispatch, commit, rootGetters }, payload) {
        const authUser = rootGetters['application/driver/authUser'];

        if (!authUser.isFullyWeb2User) {
            const transactionAllowance = await MetaWorldManager.sharedInstance().assertWallet();
            if (!transactionAllowance.isAllowed) return;
        }

        commit(MUTATIONS.SET_BUY_AUTOSHIP_DATA, payload);
        dispatch('application/popup-control/showWeb2SubscriptionPopup', true, { root: true });
    },
};
