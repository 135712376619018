export const STATE = {
    BUNDLES_DATA: 'bundlesData',
    BUNDLES_DATA_LOADING: 'bundlesDataLoading',
    PARCELS_SLOTS_DATA: 'parcelSlotsData',
    PARCELS_SLOTS_DATA_LOADING: 'parcelSlotsDataLoading',
    SINGLE_PARCEL_DATA: 'singleParcelData',
    SINGLE_PARCEL_DATA_LOADING: 'singleParcelDataLoading',
    CARDS_BUNDLES_DATA: 'cardsBundlesData',
    CARDS_BUNDLES_DATA_LOADING: 'cardsBundlesDataLoading',
    PARCEL_BUY_POPUP_VISIBLE: 'buyParcelPopupVisible',
    BTC_BUNDLES_DATA: 'btcBundlesData',
    BTC_BUNDLES_DATA_LOADING: 'btcBundlesDataLoading',
    BTC_SINGLE_PRODUCT_DATA: 'btcSingleProductData',
    BTC_SINGLE_PRODUCT_DATA_LOADING: 'btcSingleProductDataLoading',
    BTC_SINGLE_PRODUCT_ACTIVE: 'btcSingleProductActive',
} as const;

export const MUTATIONS = {
    SET_BUNDLES_DATA: 'setBundlesData',
    SET_BUNDLES_DATA_LOADING: 'setBundlesDataLoading',
    SET_PARCELS_SLOTS_DATA: 'setParcelSlotsData',
    SET_PARCELS_SLOTS_DATA_LOADING: 'setParcelSlotsDataLoading',
    SET_SINGLE_PARCEL_DATA: 'setSingleParcelData',
    SET_SINGLE_PARCEL_DATA_LOADING: 'setSingleParcelDataLoading',
    SET_CARDS_BUNDLES_DATA: 'setCardsBundlesData',
    SET_CARDS_BUNDLES_DATA_LOADING: 'setCardsBundlesDataLoading',
    SET_PARCEL_BUY_POPUP_VISIBLE: 'setBuyParcelPopupVisible',
    SET_BTC_BUNDLES_DATA: 'setBtcBundlesData',
    SET_BTC_BUNDLES_DATA_LOADING: 'setBtcBundlesDataLoading',
    SET_BTC_SINGLE_PRODUCT_DATA: 'setBtcSingleProductData',
    SET_BTC_SINGLE_PRODUCT_DATA_LOADING: 'setBtcSingleProductDataLoading',
    SET_BTC_SINGLE_PRODUCT_ACTIVE: 'setBtcSingleProductActive',
} as const;

export const ACTIONS = {
    GET_BUNDLES_DATA: 'getBundlesData',
    ON_BUNDLES_DATA_LOADING: 'onBundlesDataLoading',
    GET_BUNDLES_SPLIT_PRICES: 'getBundlesSplitPrices',
    GET_PARCELS_SLOTS_DATA: 'getParcelSlotsData',
    ON_PARCELS_SLOTS_DATA_LOADING: 'onParcelSlotsDataLoading',
    ON_CLEAR_SHOP_DATA: 'onClearShopData',
    ON_SINGLE_PARCEL_DATA: 'onSingleParcelData',
    GET_PARCEL_PRICE_INFO: 'getParcelInfoInfo',
    ON_SINGLE_PARCEL_DATA_LOADING: 'onSingleParcelDataLoading',
    ON_CARDS_BUNDLES_DATA: 'onCardsBundlesData',
    ON_CARDS_BUNDLES_DATA_LOADING: 'onCardsBundlesDataLoading',
    ON_PARCEL_BUY_POPUP_VISIBLE: 'setBuyParcelPopupVisible',
    ON_BTC_BUNDLES_DATA: 'onBtcBundlesData',
    ON_BTC_BUNDLES_DATA_LOADING: 'onBtcBundlesDataLoading',
    ON_BTC_SINGLE_PRODUCT_DATA: 'onBtcSingleProductData',
    ON_BTC_SINGLE_PRODUCT_DATA_LOADING: 'onBtcSingleProductDataLoading',
    ON_BTC_SINGLE_PRODUCT_ACTIVE: 'onBtcSingleProductActive',
} as const;

export const GETTERS = {
    BUNDLES_DATA: 'bundlesData',
    BUNDLES_DATA_LOADING: 'bundlesDataLoading',
    PARCELS_SLOTS_DATA: 'parcelSlotsData',
    PARCELS_SLOTS_DATA_LOADING: 'parcelSlotsDataLoading',
    SINGLE_PARCEL_DATA: 'singleParcelData',
    SINGLE_PARCEL_DATA_LOADING: 'singleParcelDataLoading',
    CARDS_BUNDLES_DATA: 'cardsBundlesData',
    CARDS_BUNDLES_DATA_LOADING: 'cardsBundlesDataLoading',
    PARCEL_BUY_POPUP_VISIBLE: 'buyParcelPopupVisible',
    BTC_BUNDLES_DATA: 'btcBundlesData',
    BTC_BUNDLES_DATA_LOADING: 'btcBundlesDataLoading',
    BTC_SINGLE_PRODUCT_DATA: 'btcSingleProductData',
    BTC_SINGLE_PRODUCT_DATA_LOADING: 'btcSingleProductDataLoading',
    BTC_SINGLE_PRODUCT_ACTIVE: 'btcSingleProductActive',
} as const;
