import { render, staticRenderFns } from "./GymNewButton.vue?vue&type=template&id=c2957a9a&scoped=true"
import script from "./GymNewButton.vue?vue&type=script&lang=js"
export * from "./GymNewButton.vue?vue&type=script&lang=js"
import style0 from "./GymNewButton.vue?vue&type=style&index=0&id=c2957a9a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2957a9a",
  null
  
)

export default component.exports