var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"popup-inner-content"},[_c('div',{staticClass:"d-flex flex-column gap-3"},[_c('ComposedFormItemSelect',{attrs:{"options":_setup.productOwnerOptions,"required":"","size":_setup.isMobile ? 's' : 'm',"dropdown-popper-class":"dropdown-auto-width","label":_vm.$t('select_purchase_recipient')},model:{value:(_setup.productOwner),callback:function ($$v) {_setup.productOwner=$$v},expression:"productOwner"}}),_vm._v(" "),(_setup.productOwner === _setup.OWNERS.ANOTHER_USER)?[_c('ComposedFormItemInput',{attrs:{"label":_vm.$t('owner_email'),"placeholder":_vm.$t('type_here'),"name":"email","type":"email","error":_vm.$t(_setup.emailError),"info":_setup.giftUserInfo,"size":_setup.isMobile ? 's' : 'm',"required":"","icon":_setup.isWalletAddressLoading ? {
                    name: 'loading-animation',
                    path: 'general',
                    size: 's',
                } : null},model:{value:(_setup.selectedEmail),callback:function ($$v) {_setup.selectedEmail=$$v},expression:"selectedEmail"}})]:_vm._e(),_vm._v(" "),_c('div',{staticClass:"d-flex flex-column gap-2"},[(_setup.productOwner === _setup.OWNERS.CURRENT_USER && _setup.showInfoText)?_c('BaseTextInfoMessage',{attrs:{"text":_vm.$t('indicate_recipient_self_or_another_user')}}):_vm._e(),_vm._v(" "),_vm._l((_setup.infoTexts),function(text,index){return (_setup.infoTexts && _setup.infoTexts.length)?_c('BaseTextInfoMessage',{key:`info-${index}`,attrs:{"text":_vm.$t(text.text, text.params)}}):_vm._e()})],2),_vm._v(" "),_c('BaseButtonSimple',{staticClass:"w-100 mt-2",attrs:{"type":"cta","styling":"primary","size":_setup.isMobile ? 's' : 'm',"disabled":_setup.productOwner === _setup.OWNERS.ANOTHER_USER &&
                 (!_setup.isEmailValid || !_setup.ownerWalletAddress) ||
                 _setup.productOwner === '' ||
                 _setup.isWalletAddressLoading},nativeOn:{"click":function($event){return _setup.constructProductOwnerAndEmit.apply(null, arguments)}}},[_vm._v("\n            "+_vm._s(_vm.$tc('Continue'))+"\n        ")])],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }