import { runControlledInterval } from "../runControlledinterval";
import { getStorePollingNames } from "./getStorePollingNames";

type Names = ReturnType<typeof getStorePollingNames>;

export function setupStorePolling(
    storeNames: Names,
    customCallback: ((context) => unknown) | string,
    logName: string,
) {
    const {
        state: STATE,
        actions: ACTIONS,
        getters: GETTERS,
        mutations: MUTATIONS,
    } = storeNames;
    const intervalCallback = typeof customCallback === 'function'
        ? customCallback
        : (context) => {
            const { dispatch, getters } = context;
            const clearFunction = getters[GETTERS.CLEAR_FUNCTION];
            return dispatch(
                customCallback,
                { 
                    save: () => !!getters[GETTERS.CLEAR_FUNCTION] &&
                                (!clearFunction || clearFunction === getters[GETTERS.CLEAR_FUNCTION]),
                },
            );
        }
    return {
        actions: {
            [ACTIONS.POLL_DATA] (ctx, intervalProps: Parameters<typeof runControlledInterval>[1]) {
                const {
                    dispatch,
                    commit,
                } = ctx;
                dispatch(ACTIONS.STOP_POLLING);
                const {
                    result,
                    clear,
                    changeInterval,
                } = runControlledInterval(() => intervalCallback(ctx), intervalProps || {});
                commit(MUTATIONS.SET_CLEAR_FUNCTION, clear);
                commit(MUTATIONS.SET_POLLING_INTERVAL_CHANGER, changeInterval);
                return result;
            },
            [ACTIONS.STOP_POLLING] ({ commit, getters }) {
                if (getters[GETTERS.CLEAR_FUNCTION]) {
                    getters[GETTERS.CLEAR_FUNCTION]();
                }
                commit(MUTATIONS.SET_CLEAR_FUNCTION, null);
                commit(MUTATIONS.SET_POLLING_INTERVAL_CHANGER, null);
            },

            [ACTIONS.CHANGE_POLLING_INTERVAL] ({ commit, getters }, interval: number) {
                if (getters[GETTERS.POLLING_INTERVAL_CHANGER]) {
                    getters[GETTERS.POLLING_INTERVAL_CHANGER](interval);
                } else {
                    console.warn(`Polling interval changer is not set for ${logName}`);
                }
            },
        },
        state: {
            [STATE.CLEAR_FUNCTION]: null,
            [STATE.POLLING_INTERVAL_CHANGER]: null,
        },
        mutations: {
            [MUTATIONS.SET_CLEAR_FUNCTION]: (state, clear) => state[GETTERS.CLEAR_FUNCTION] = clear,
            [MUTATIONS.SET_POLLING_INTERVAL_CHANGER]: (state, changeInterval) => state[GETTERS.POLLING_INTERVAL_CHANGER] = changeInterval,
        },
        getters: {
            [GETTERS.CLEAR_FUNCTION]: (state) => state[GETTERS.CLEAR_FUNCTION],
            [GETTERS.POLLING_INTERVAL_CHANGER]: (state) => state[GETTERS.POLLING_INTERVAL_CHANGER],
        },
    };
}