import { ACTIONS } from "./names";
import { ACTIONS as USER_FEEDBACK_ACTIONS } from "~/store/affility/user/feedback/modals/full-loader/names";

export default {
    [ACTIONS.UPDATE_USER_AUTH_DATA]({ commit }, data: Record<string, unknown>) {
        const currentAuthUserData = this.$auth.user;
        this.$auth.$storage.setState('user', {
            ...currentAuthUserData,
            ...data,
        });
        commit("application/driver/UPDATE_AUTH_USER", {
            ...data,
        }, { root: true });
    },
    async [ACTIONS.LOG_OUT]({ commit, dispatch }) {
        dispatch(`affility/user/feedback/modals/full-loader/${USER_FEEDBACK_ACTIONS.OPEN_FULL_PAGE_LOADING}`,
            null, {root: true});
        await this.$auth.logout();
        await dispatch('application/driver/logOut', null, {root: true});
        await this.$router.push('/shop');
        dispatch(`affility/user/feedback/modals/full-loader/${USER_FEEDBACK_ACTIONS.CLOSE_FULL_PAGE_LOADING}`, {
            delay: 1000,
        }, {root: true});
    },
};
