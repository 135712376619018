var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('BaseSwitchMonochromeIconedToggle',{staticClass:"theme-switch",attrs:{"size":_setup.size,"theme":_setup.theme,"value":_setup.$colorMode.value === 'dark',"disabled":_setup.disabled,"offIcon":{
        name: 'light-mode',
        path: 'general',
        color: 'neutral-surface-shade-50-90',
    },"onIcon":{
        name: 'dark-mode',
        path: 'general',
        color: 'neutral-surface-shade-50-90',
    }},on:{"input":_setup.onModeChange}})
}
var staticRenderFns = []

export { render, staticRenderFns }