import { render, staticRenderFns } from "./CloseButtonAndHeading.vue?vue&type=template&id=9f7a0d1a&scoped=true"
import script from "./CloseButtonAndHeading.vue?vue&type=script&setup=true&lang=ts"
export * from "./CloseButtonAndHeading.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./CloseButtonAndHeading.vue?vue&type=style&index=0&id=9f7a0d1a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9f7a0d1a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseButtonClose: require('/root/build_gs/core/components/base/button/Close.vue').default,BasePopupBasic: require('/root/build_gs/core/components/base/popup/Basic.vue').default})
