export const STATE = {
    BANNERS: 'BANNERS', 
} as const;

export const ACTIONS = {
} as const;

export const MUTATIONS = {
} as const;

export const GETTERS = {
    BANNERS: 'BANNERS',
} as const;