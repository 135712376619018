var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"chips-simple",class:[
        _vm.type,
        _vm.size,
        {
            'is-active': _vm.isActive,
        }
    ]},[_vm._t("left-icon"),_vm._v(" "),_vm._t("default"),_vm._v(" "),_vm._t("right-icon")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }