export enum PriceFilterTypes {
    USDT = 'USDT',
    PAP = 'PAP',
    UTILITY = 'UTILITY',
    GYMNET = 'GYMNET',
}

export enum ProductFilterBTCType {
    BUNDLES = 'BUNDLES',
    SINGLE_PRODUCT = 'SINGLE_PRODUCT',
}

export enum ProductFilterAffilityType {
    BUNDLES = 'BUNDLES',
    PARCELS = 'PARCELS',
    CARDS = 'CARDS',
    PRODUCT_AUTOSHIP = 'PRODUCT_AUTOSHIP',
}
