import { render, staticRenderFns } from "./PaymentSuccessful.vue?vue&type=template&id=0d628872&scoped=true"
import script from "./PaymentSuccessful.vue?vue&type=script&lang=ts&setup=true"
export * from "./PaymentSuccessful.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./PaymentSuccessful.scss?vue&type=style&index=0&id=0d628872&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d628872",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseImageSvgThemedIcon: require('/root/build_gs/core/components/base/image/SvgThemedIcon.vue').default,BaseButtonSimple: require('/root/build_gs/core/components/base/button/Simple.vue').default})
