import { GETTERS, STATE } from "./names";
import { State } from "./types";

export default {
    [GETTERS.BUNDLES_DATA]: (state: State) => state[STATE.BUNDLES_DATA],
    [GETTERS.BUNDLES_DATA_LOADING]: (state: State) => state[STATE.BUNDLES_DATA_LOADING],
    [GETTERS.PARCELS_SLOTS_DATA]: (state: State) => state[STATE.PARCELS_SLOTS_DATA],
    [GETTERS.PARCELS_SLOTS_DATA_LOADING]: (state: State) => state[STATE.PARCELS_SLOTS_DATA_LOADING],
    [GETTERS.SINGLE_PARCEL_DATA]: (state: State) => state[STATE.SINGLE_PARCEL_DATA],
    [GETTERS.SINGLE_PARCEL_DATA_LOADING]: (state: State) => state[STATE.SINGLE_PARCEL_DATA_LOADING],
    [GETTERS.CARDS_BUNDLES_DATA]: (state: State) => state[STATE.CARDS_BUNDLES_DATA],
    [GETTERS.CARDS_BUNDLES_DATA_LOADING]: (state: State) => state[STATE.CARDS_BUNDLES_DATA_LOADING],
    [GETTERS.PARCEL_BUY_POPUP_VISIBLE]: (state: State) => state[STATE.PARCEL_BUY_POPUP_VISIBLE],
    [GETTERS.BTC_BUNDLES_DATA]: (state: State) => state[STATE.BTC_BUNDLES_DATA],
    [GETTERS.BTC_BUNDLES_DATA_LOADING]: (state: State) => state[STATE.BTC_BUNDLES_DATA_LOADING],
    [GETTERS.BTC_SINGLE_PRODUCT_DATA]: (state: State) => state[STATE.BTC_SINGLE_PRODUCT_DATA],
    [GETTERS.BTC_SINGLE_PRODUCT_DATA_LOADING]: (state: State) => state[STATE.BTC_SINGLE_PRODUCT_DATA_LOADING],
    [GETTERS.BTC_SINGLE_PRODUCT_ACTIVE]: (state: State) => state[STATE.BTC_SINGLE_PRODUCT_ACTIVE],
}
