
import {mapGetters} from "vuex";
import GymNewButton from "@/components/utils/form-components/GymNewButton";
import ApiService from "~/core/services/api-interaction/ApiService";
import CustomLoader from "~/components/utils/CustomLoader";
import {ACTIONS as AUTH_ACTIONS} from '~/store/affility/user/auth/names';

export default {
    name: "UsernamePopup",
    components: {
        CustomLoader,
        GymNewButton,
    },
    data () {
        return {
            username: '',
            usernameAlreadyTaken: false,
            isLoading: false,
            showSuccessContent: false,
            usernameAvailable: false,
            isCheckingUsername: false,
        };
    },
    computed: {
        ...mapGetters('application/driver', ['authUser']),
        validateUsername () {
            if (this.username) {
                const usernameRegex = /^(?![-._])[a-z][-._a-z0-9]{7,}$/;
                return usernameRegex.test(this.username);
            }
        },
    },
    methods: {
        async confirm () {
            this.usernameAlreadyTaken = false;
            this.isLoading            = true;
            try {
                await ApiService.post(`/user/username-change`, {username: this.username});
                await this.$store.dispatch(`affility/user/auth/${AUTH_ACTIONS.UPDATE_USER_AUTH_DATA}`, {
                    username: this.username,
                });
                this.showSuccessContent = true;
            } catch (e) {
                this.usernameAlreadyTaken = true;
            } finally {
                this.isLoading = false;
            }
        },
        async handleExistUsername () {
            this.usernameAlreadyTaken = false;
            this.usernameAvailable    = false;
            if (this.validateUsername) {
                this.isCheckingUsername = true;
                try {
                    await ApiService.post(`/user/username-change`, {username: this.username, type: 'exist'});
                    this.usernameAvailable = true;
                } catch (e) {
                    this.usernameAlreadyTaken = true;
                } finally {
                    this.isLoading = false;
                    this.isCheckingUsername = false;
                }
            }
        },
        closePopup () {
            return this.$store.dispatch(`affility/user/auth/${AUTH_ACTIONS.UPDATE_USER_AUTH_DATA}`, {
                username: this.username,
            }, {root: true});
        },
        async handleLogOut() {
            try {
                await this.$auth.logout();
                await this.$store.dispatch('application/driver/logOut');
                this.$router.push('/shop');
            } catch (err) {
                console.log(err);
            }
        }
    },
};
