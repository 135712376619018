import { render, staticRenderFns } from "./CurrencySelection.vue?vue&type=template&id=686f8747&scoped=true"
import script from "./CurrencySelection.vue?vue&type=script&lang=ts&setup=true"
export * from "./CurrencySelection.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./CurrencySelection.scss?vue&type=style&index=0&id=686f8747&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "686f8747",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseImageSvgThemedIcon: require('/root/build_gs/core/components/base/image/SvgThemedIcon.vue').default,ComposedFormItemCheckbox: require('/root/build_gs/core/components/composed/form-item/Checkbox.vue').default,BaseRadioSimple: require('/root/build_gs/core/components/base/radio/Simple.vue').default,BaseTextInfoMessage: require('/root/build_gs/core/components/base/text/InfoMessage.vue').default,BaseButtonSimple: require('/root/build_gs/core/components/base/button/Simple.vue').default})
