import { ACTIONS as AUTH_ACTIONS, GETTERS as AUTH_GETTERS } from "../../auth/names";
import { ACTIONS as USER_FEEDBACK_ACTIONS } from "../../feedback/names";
import { ACTIONS, MUTATIONS } from "./names";
import AuthService from "~/core/services/api-interaction/AuthService";

export default {
    [ACTIONS.CLEAR_EMAIL_VERIFICATION_STATE]({ commit }) {
        commit(MUTATIONS.SET_IS_VERIFYING_EMAIL, false);
        commit(MUTATIONS.SET_EMAIL_SENT_NOTIFICATION_VISIBLE, false);
    },
    async [ACTIONS.RESEND_LINK_MUTATE_USER_AND_SHOW_NOTIFICATION]({ dispatch }, { email }) {
        await dispatch(ACTIONS.RESEND_EMAIL_VERIFICATION_LINK_AND_MUTATE_USER, { email });
        return dispatch(ACTIONS.SHOW_NOTIFICATION_AND_CLOSE_FLOW, { logoutIfNecessary: false, email });
    },
    async [ACTIONS.RESEND_EMAIL_VERIFICATION_LINK_AND_MUTATE_USER]({ dispatch }, { email }) {
        await dispatch(ACTIONS.RESEND_EMAIL_VERIFICATION_LINK, email);
        const lastEmailSent = new Date().toISOString();
        await dispatch(`affility/user/auth/${AUTH_ACTIONS.UPDATE_USER_AUTH_DATA}`, {
            lastEmailSent,
            last_email_sent: lastEmailSent,
        }, { root: true });
    },
    [ACTIONS.RESEND_EMAIL_VERIFICATION_LINK] (_ctx, email: string) {
        return AuthService.resendVerificationEmail(email);
    },
    async [ACTIONS.CHANGE_EMAIL_MUTATE_USER_AND_SHOW_NOTIFICATION]({ dispatch }, { email }) {
        await dispatch(ACTIONS.CHANGE_EMAIL_AND_MUTATE_USER, { email });
        return dispatch(ACTIONS.SHOW_NOTIFICATION_AND_CLOSE_FLOW, { logoutIfNecessary: false, email });
    },
    async [ACTIONS.CHANGE_EMAIL_AND_MUTATE_USER]({ dispatch }, { email }) {
        await dispatch(ACTIONS.CHANGE_EMAIL, email);
        const lastEmailSent = new Date().toISOString();
        await dispatch(`affility/user/auth/${AUTH_ACTIONS.UPDATE_USER_AUTH_DATA}`, {
            email,
            lastEmailSent,
            last_email_sent: lastEmailSent,
        }, { root: true });
    },
    [ACTIONS.CHANGE_EMAIL] (_ctx, email: string) {
        return AuthService.changeEmail(email);
    },
    [ACTIONS.START_EMAIL_VERIFICATION] ({ commit }) {
        commit(MUTATIONS.SET_IS_VERIFYING_EMAIL, true);
    },
    [ACTIONS.SHOW_NOTIFICATION_AND_CLOSE_FLOW] ({ dispatch }, cancelEmailParams ) {
        dispatch(ACTIONS.CANCEL_EMAIL_VERIFICATION, cancelEmailParams);
        return dispatch(ACTIONS.SHOW_EMAIL_SENT_NOTIFICATION, cancelEmailParams );
    },
    [ACTIONS.CANCEL_EMAIL_VERIFICATION] ({ commit, dispatch, rootGetters }, {
        logoutIfNecessary = true,
    } = {}) {
        if (logoutIfNecessary &&
            rootGetters[`affility/user/auth/${AUTH_GETTERS.CURRENT_USER}`]?.emailConfirmationOverdued) {
            dispatch(`affility/user/auth/${AUTH_ACTIONS.LOG_OUT}`, null, { root: true });
        }
        commit(MUTATIONS.SET_IS_VERIFYING_EMAIL, false);
    },
    async [ACTIONS.SHOW_EMAIL_SENT_NOTIFICATION] ({ commit, dispatch }, { email }) {  
        commit(MUTATIONS.SET_EMAIL_SENT_NOTIFICATION_VISIBLE, true);
        const modalClosePromise = dispatch(
            `affility/user/feedback/${USER_FEEDBACK_ACTIONS.SHOW_SUCCESS_MODAL}`,
            {
                title: this.app.i18n.t('email_verification_sent_title'),
                desc: this.app.i18n.t(`email_verification_sent_description`, { email }),
            },
            {
                root: true,
            },
        );
        await modalClosePromise;
        commit(MUTATIONS.SET_EMAIL_SENT_NOTIFICATION_VISIBLE, false);
    },


    [ACTIONS.HIDE_EMAIL_SENT_NOTIFICATION] ({ commit }) {
        commit(MUTATIONS.SET_EMAIL_SENT_NOTIFICATION_VISIBLE, false);
    },
    [ACTIONS.HIDE_EMAIL_SENT_NOTIFICATION_AND_OPEN_FLOW] ({ dispatch }) {
        dispatch(ACTIONS.HIDE_EMAIL_SENT_NOTIFICATION);
        dispatch(ACTIONS.START_EMAIL_VERIFICATION);
    },
};
