export const STATE = {
    WRONG_WALLET_MODAL_VISIBLE: 'wrongWalletModalVisible',
    WRONG_WALLET_MODAL_ADDRESS: 'wrongWalletModalAddress',
} as const;

export const ACTIONS = {
    SHOW_ERROR_MODAL: 'showErrorModal',
    SHOW_INFO_MODAL: 'showInfoModal',
    SHOW_SUCCESS_MODAL: 'showSuccessModal',
    SHOW_CONFIRM_MODAL: 'showConfirmModal',
    SHOW_WRONG_WALLET_MODAL: 'showWrongWalletModal',
} as const;

export const MUTATIONS = {
    SET_WRONG_WALLET_MODAL_DATA: 'setWrongWalletModalData',
} as const;

export const GETTERS = {
    WRONG_WALLET_MODAL_VISIBLE: 'wrongWalletModalVisible',
    WRONG_WALLET_MODAL_ADDRESS: 'wrongWalletModalAddress',
} as const;
