import MunicipalityTx from './MunicipalityTx';
import { toWei } from '@/core/helpers/GlobalHelpers';
import { MetaWorldManager } from '~/core/services/map/MetaWorldManager';
import { AllowanceInfo } from "~/core/models/purchase-popup-2/AllowanceInfo";

const GAS_FEE_MULTIPLIER = 1.5;

export default class Web3MunicipalityTx extends MunicipalityTx {

    constructor (internalTransaction: MunicipalityTx) {
        super(
            internalTransaction._currency,
            internalTransaction._gymnet,
            internalTransaction._municipality,
            internalTransaction.method,
            internalTransaction.args,
            internalTransaction.account,
            internalTransaction.priceInfo,
        );
    }

    async approve () {
        const metaWorldManager = MetaWorldManager.sharedInstance();
        const readWeb3 = metaWorldManager.readWeb3();
        const gasPrice = await readWeb3.eth.getGasPrice();
        const { account: outgoingAccount } = this;
        const approveAmount = this._isUsingExtraBalance ? this.priceInfo.splitAmountUtility.usdt.value : this.priceInfo.totalAmount.value;
        const requestPrice = toWei(String(approveAmount));

        const tx = this._currency.methods
            .approve(this._municipality.options.address, requestPrice);

        const estimatedGas = await tx.estimateGas({
            from: outgoingAccount,
        });

        const response = await tx
            .send({
                from: outgoingAccount,
                gas: Math.floor(estimatedGas * GAS_FEE_MULTIPLIER),
                gasPrice,
            }).on("error", (error) => {
                throw new Error(error.message)
            });

        this.lastAllowance = new AllowanceInfo(
            approveAmount,
            requestPrice,
            approveAmount,
            true,
        );

        return response.status;
    }

    async approveGymnet () {
        const metaWorldManager = MetaWorldManager.sharedInstance();
        const readWeb3 = metaWorldManager.readWeb3();
        const gasPrice = await readWeb3.eth.getGasPrice();
        const { account: outgoingAccount } = this;
        const requestPrice = toWei(String(Math.ceil(this.priceInfo.splitAmountGymnet.gymnet.value)));
        const tx = this._gymnet.methods.approve(this._municipality.options.address, requestPrice);

        const estimatedGas = await tx.estimateGas({
            from: outgoingAccount,
        })

        const response = await tx
            .send({
                from: outgoingAccount,
                gas: Math.floor(estimatedGas * GAS_FEE_MULTIPLIER),
                gasPrice,
            }).on("error", (error) => {
                throw new Error(error.message)
            })

        this.lastGymnetAllowance = new AllowanceInfo(
            this.priceInfo.splitAmountGymnet.gymnet.value,
            requestPrice,
            this.priceInfo.splitAmountGymnet.gymnet.value,
            true,
        );

        return response.status;
    }

    async send () {
        const metaWorldManager = MetaWorldManager.sharedInstance();
        const readWeb3 = metaWorldManager.readWeb3();
        const gasPrice = await readWeb3.eth.getGasPrice();
        const { account: outgoingAccount } = this;
        const { args } = this;

        const tx = this._municipality.methods[this.method](...args);

        const estimatedGas = await tx.estimateGas({
            from: outgoingAccount,
        });

        return await tx.send({
            from: outgoingAccount,
            gas: Math.floor(estimatedGas * GAS_FEE_MULTIPLIER),
            gasPrice,
        });
    }
}
