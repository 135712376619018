import { render, staticRenderFns } from "./PaymentMethodSelection.vue?vue&type=template&id=2707f202&scoped=true"
import script from "./PaymentMethodSelection.vue?vue&type=script&lang=ts&setup=true"
export * from "./PaymentMethodSelection.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./PaymentMethodSelection.scss?vue&type=style&index=0&id=2707f202&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2707f202",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseTextInfoMessage: require('/root/build_gs/core/components/base/text/InfoMessage.vue').default,ComposedFormItemSelect: require('/root/build_gs/core/components/composed/form-item/Select.vue').default,BaseButtonSimple: require('/root/build_gs/core/components/base/button/Simple.vue').default})
