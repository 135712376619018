import { CashFtBundleNamesById } from "../constants/CashFtBundleNamesById";
import { MetaBundle } from "~/core/models/MetaBundle";
import { CashFtBundleIds } from "~/pages/nfts/products/constants/CashFtBundleIds";

export class CashFtCardBundle extends MetaBundle {
    public name: string;

    constructor(
        public readonly id: number,
        public readonly numberOfParcels: number,
        public readonly isCurrentlyActive: boolean,
        public totalPurchasedCount: number,
        public price: number,
        public minimumGymnetDeposit: number,
    ) {
        super({
            name: CashFtBundleNamesById[id],
            sc_id: id,
            number_of_parcels: numberOfParcels,
            price,
            discounted_price: price,
        });
        this.name = CashFtBundleNamesById[id];
    }

    get image() {
        try {
            return require(`@/assets/affility/images/product/affility/cards/card-parcel-${this.id}.webp`);
        } catch {
            return require('@/assets/affility/images/product/affility/parcels/parcel.webp');
        }
    }

    get cardImage() {
        try {
            return require(`@/assets/affility/images/product/affility/cards/card-image-${this.id}.webp`);
        } catch {
            return '';
        }
    }
}
