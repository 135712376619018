import { getClientRuntimeConfig } from "~/core/helpers/GlobalHelpers";
import { getChainById } from "~/constants/networks";

const $config = getClientRuntimeConfig();

export const EMAIL_VERIFICATION_DAYS = 3;

export const METAVERSE_SWITCH_ASK_KEY = "metaverse-switch-ask";

export const MINER_UNIT_PRICE = 50;

export const INVENTORY_TRANSACTION_TYPES = {
    BUY_PARCEL: 10,
    BUY_MINER: 30,
    ATTACH_MINER: 40,
};

export const PARCEL_LAND_TYPES = {
    OCEAN: 10,
    NEAR_OCEAN: 20,
    INLAND: 30,
};

export const PARCEL_TYPES = {
    STANDARD: 10,
    BUSINESS: 20,
    UPGRADED: 30,
    RESERVED: 40,
};

export const IMAGE_STATUS_TYPES = {
    WAITING_FOR_UPLOAD: 0,
    PENDING: 1,
    APPROVED: 2,
};

export const PARCEL_UPGRADED_MINERS_COUNT = 10;
export const PARCEL_MINERS_COUNT = 4;

export const MAP_ENDPOINTS = {
    reserved_areas: `${$config.apiBaseUrl}/map/reserved`,
    reserved_areas_data: `${$config.apiBaseUrl}/map/parcels`,
    business_parcels_data: `${$config.apiBaseUrl}/map/business-parcels`,
    image_parcels_data: `${$config.apiBaseUrl}/map/parcel-images`,
    owned_parcels_data: `${$config.apiBaseUrl}/map/owned-parcels`,
};

export const LOGIN_OPTIONS = {
    WEB3: 'web3',
    DISCORD: 'discord',
    GOOGLE: 'google',
    TWITTER: 'twitter',
    COINBASE: 'coinbase',
};

export const ERROR_STATUSES = {
    // Invalid verification code entered during the registration process
    INVALID_VERIFICATION_CODE: 10,

    // Email address exists or the format is invalid
    INVALID_EMAIL_ADDRESS: 20,

    // Can't update the user data, probably because the email address was already set and verified
    INVALID_USER_UPDATE: 30,

    // Authorization related error occurred
    AUTHORIZATION_ERROR: 40,

    // System exception occurred.  In this case the error has also a message from the thrown exception
    EXCEPTION: 50,

    // Invalid arguments were supplied to API
    INVALID_ARGS: 60,

    // Maximum file size exceeded
    MAX_FILE_SIZE_EXCEEDED: 61,

    // Unexpected case occurred in application
    INVALID_CASE: 70,

    // Supplied JWT token is invalid
    INVALID_TOKEN: 80,

    // Supplied email address already exists in the system
    EXISTING_EMAIL: 90,

    // Insufficient permissions for performing the action
    PERMISSION_DENIED: 100,

    // Parcels selection data integrity error
    PARCELS_SELECTION_DATA_INTEGRITY_ERROR: 110,

    // Data not found
    DATA_NOT_FOUND: 120,

    // NULL returned from a call to init-wallet as a value of referrer_code
    INVALID_REFERRER_CODE: 130,

    // User doesn't exist in MLM
    INVALID_MLM_USER: 140,

    // User's wallet_address not miatching with related_wallet_address
    MISMATCHED_WALLET_ADDRESS: 150,

    // Supplied wallet address already exists in the system
    EXISTING_WALLET: 160,

    INVALID_WALLET_SIGNATURE: 170,

    // wallet address not found in the system
    WALLET_NOT_FOUND: 180,

    // User nft balance is not enough to claim
    NOT_ENOUGH_BALANCE: 190,

    // Parcels data is not valid (reserved or owned by someone else)
    WRONG_PARCELS_DATA: 200,

    // EVM returned an error
    EVM_ERROR: 210,

    // Exist username
    EXISTING_USERNAME: 220,

    //TX error for gas fee
    TX_ERROR_GAS: 230,

    IP_ADDRESS_BANNED: 240,

    // User closed the OAuth window for service
    USER_CLOSED_OAUTH_WINDOW: 4001, // TODO remove this if not needed

    // User denied the OAuth request for service
    USER_DENIED_OAUTH_ACCESS: 4002,
};

export const GYMSTREET_STATE = {
    WALLET_NOT_CONNECTED: 0,
    INIT_WALLET_IN_PROGRESS: 10,
    LOGIN_IN_PROGRESS: 20,
    SIGNATURE_GRANT_IN_PROGRESS: 30,
    REQUEST_FAILED: 40,
    ATTACH_WALLET_IN_PROGRESS: 60,
};

export const AUTH_FLOW_STEPS = {
    CONNECT_WALLET: "connect-wallet",
    SIGN_UP_WITH_EMAIL: "sign-up-with-email",
    SIGN_IN_WITH_EMAIL: "sign-in-with-email",
    FORGOT_PASSWORD: "forgot-password",
    TWO_FACTOR_CODE: "two-factor-code",
};

export const AUTH_FLOW_ERRORS = {
    SOMETHING_WENT_WRONG: 405,
    EMAIL_ALREADY_IN_USE: 406,
    EMAIL_ALREADY_VERIFIED: 407,
    INVALID_VERIFICATION_CODE: 408,
    INVALID_EMAIL_OR_PASSWORD: 409,
    EMAIL_NOT_VERIFIED: 410,
    EMAIL_DOESNT_EXIST: 411,
    TOKEN_EXPIRED: 412,
    WALLET_PROCESSING: 413,
    TOO_MANY_REQUESTS: 414,
};

export const BROADCAST_CHANNEL_NAME = "gymstreet-channel";
export const BROADCAST_EVENT_TYPES = {
    STORE_DISPATCH: "store-dispatch",
    STORE_COMMIT: "store-commit",
};

export const CURRENCIES = {
    BUSD: 'BUSD',
    USDT: 'USDT',
    PAP: 'PAP',
};

export const BUNDLE_TYPE_PARCELS_MINERS_1 = 0;
export const BUNDLE_TYPE_STARTER_BUNDLE = 26;
export const BUNDLE_TYPE_SUPER_BUNDLE_R6 = 13;
export const BUNDLE_TYPE_SUPER_BUNDLE_R8 = 25;
export const BUNDLE_TYPE_SUPER_BUNDLE_R10 = 14;
export const BUNDLE_TYPE_SUPER_BUNDLE_R15 = 15;
export const BUNDLE_TYPE_SUPER_BUNDLE_R20 = 16;
export const BUNDLE_TYPE_SUPER_BUNDLE_R22_S = 17;
export const BUNDLE_TYPE_SUPER_BUNDLE_R22_M = 18;
export const BUNDLE_TYPE_SUPER_BUNDLE_R22_L = 19;
export const BUNDLE_TYPE_SUPER_BUNDLE_R22_XL = 20;
export const BUNDLE_TYPE_SUPER_BUNDLE_R22_XXL = 21;
export const SINGLE_MINER = 22;
export const SINGLE_PARCEL = 23;

export const BUNDLE_NAMES = {
    0: 'Basic Bundle',
    1: '4 Parcels Bundle',
    2: '10 Parcels Bundle',
    3: '40 Parcels Bundle',
    4: '80 Parcels Bundle',
    5: '140 Parcels Bundle',
    6: '240 Parcels Bundle',
    7: '4 Basic Miner Bundle',
    8: '10 Basic Miner Bundle',
    9: '40 Basic Miner Bundle',
    10: '80 Basic Miner Bundle',
    11: '140 Basic Miner Bundle',
    12: '240 Basic Miner Bundle',
    13: 'Super Bundle R6',
    14: 'Super Bundle R10',
    15: 'Super Bundle R15',
    16: 'Super Bundle R20',
    17: 'Super Bundle R22 S',
    18: 'Super Bundle R22 M',
    19: 'Super Bundle R22 L',
    20: 'Super Bundle R22 XL',
    21: 'Super Bundle R22 XXL',
    25: 'Super Bundle R8',
    26: 'Starter Bundle',
    27: 'Super Bundle R17',
    28: 'Super Bundle R9',
    // Affility super bundles
    29: 'Super Bundle R6',
    30: 'Super Bundle R8',
    31: 'Super Bundle R9',
    32: 'Super Bundle R10',
    33: 'Super Bundle R15',
    34: 'Super Bundle R17',
    35: 'Super Bundle R20',
    36: 'Super Bundle R22 S',
    37: 'Super Bundle R22 M',
};

export const BTC_SINGLE_PRODUCT_NAMES = {
    40: 'TH15',
    41: 'TH37',
    42: 'TH67',
    43: 'TH92',
    44: 'TH162',
    45: 'TH198',
    46: 'TH233',
    47: 'TH341',
    48: 'TH609',
}

export const BUNDLE_NAMES_OTHERS = {
    22: 'Single miner',
    23: 'Single parcel',
    24: 'Upgrade'
};
export const BUNDLE_NAME_AUTOSHIP = {
    2000: 'Product Autoship Monthly',
    2001: 'Product Autoship Yearly',
};

export const BUNDLE_NAMES_BITOPEX = {
    0: 'Basic Bundle',
    1: '4 Parcels Bundle',
    2: '10 Parcels Bundle',
    3: '40 Parcels Bundle',
    4: '80 Parcels Bundle',
    5: '140 Parcels Bundle',
    6: '240 Parcels Bundle',
    7: '4  Miner Bundle',
    8: '10  Miner Bundle',
    9: '40  Miner Bundle',
    10: '80  Miner Bundle',
    11: '140  Miner Bundle',
    12: '240  Miner Bundle',
    13: 'Super Bundle R6',
    14: 'Super Bundle R10',
    15: 'Super Bundle R15',
    16: 'Super Bundle R20',
    17: 'Super Bundle R22 S',
    18: 'Super Bundle R22 M',
    19: 'Super Bundle R22 L',
    20: 'Super Bundle R22 XL',
    21: 'Super Bundle R22 XXL',
};

export const BUNDLE_NAMES_CARDS = {
    100: 'Explorer Card',
    101: 'Mastery Card',
}

export const CONTRACT_ADDRESSES = {
    GYMNET: '0x0012365F0a1E5F30a5046c680DCB21D07b15FcF7',
    VBTC: '0x268bb0f44ab880be59ccd2b96bfa138211e27a20',
};

export const MINER_HASH_POWER = 1500;

export const NOTIFICATION_TYPES = {
    TEXT_NOTIFICATION: 'TextNotification',
    IMAGE_APPROVED_NOTIFICATION: 'ImageApprovedNotification',
    IMAGE_REJECTED_NOTIFICATION: 'ImageRejectedNotification',
    ACCOUNT_UPDATED_NOTIFICATION: 'AccountUpdatedNotification',
    BALANCE_UPDATED_NOTIFICATION: 'BalanceUpdatedNotification',
    TEXT_TEMPLATE_NOTIFICATION: 'TextTemplateNotification',
};

export const USER_PERMISSION_TYPES = {
    BUY_MINER: 10,
    CLAIM_FROM_BALANCE: 20,
};
export const NULLABLE_WALLET_ADDRESS = '0x00000000000000000000000000000000000000cD';
export const SUPPORTED_CURRENCIES = [
    {
        NAME: 'Bitcoin',
        CODE: 'BTC',
        ICON: require('~/assets/images/icons/currencies/btc.png'),
        GAS_FEE: 0.0005,
        IS_ACTIVE: $config.chainId === 56,
        TOKEN_STANDARD: 'BTC',
        ACTUAL_TOKEN_STANDARD: 'Native',
        CHAIN: null,
        PAYMENT_TEMPLATE: `bitcoin:{TRANSFER_TO}?amount={AMOUNT}`,
    },
    {
        NAME: 'Ether',
        CODE: 'ETH',
        ICON: require('~/assets/images/icons/currencies/eth.png'),
        GAS_FEE: 0.00126,
        IS_ACTIVE: true,
        TOKEN_STANDARD: 'ERC20',
        ACTUAL_TOKEN_STANDARD: 'Native',
        CHAIN: getChainById($config.chainId === 56 ? 1 : 5), // If BSC mainnet is used then use ETH mainnet, otherwise use ETH Goerli testnet
        PAYMENT_TEMPLATE: `ethereum:{TRANSFER_TO}@{CHAIN_ID}?value={AMOUNT}`,
        IS_EVM_BASED: true,
        CHAIN_ID: $config.chainId === 56 ? 1 : 5,
    },
    {
        NAME: 'Binance Coin',
        CODE: 'BNB',
        ICON: require('~/assets/images/icons/currencies/bnb.png'),
        GAS_FEE: 0.00126,
        IS_ACTIVE: true,
        TOKEN_STANDARD: 'BEP20',
        ACTUAL_TOKEN_STANDARD: 'Native',
        CHAIN: getChainById($config.chainId),
        PAYMENT_TEMPLATE: `ethereum:{TRANSFER_TO}@{CHAIN_ID}?value={AMOUNT}`,
        IS_EVM_BASED: true,
        CHAIN_ID: $config.chainId,
    },
    // {
    //     NAME: 'Avalanche',
    //     CODE: 'AVAX',
    //     ICON: require('~/assets/images/icons/currencies/avax.png'),
    //     GAS_FEE: 0.00126,
    //     IS_ACTIVE: true,
    //     TOKEN_STANDARD: 'ERC20',
    //     CHAIN: getChainById($config.chainId === 56 ? 43114 : 43113),
    // },
    {
        NAME: 'BUSD (Binance Smart Chain)',
        CODE: 'BUSD',
        ICON: require('~/assets/images/icons/currencies/busd.png'),
        GAS_FEE: 0.00126,
        IS_ACTIVE: true,
        TOKEN_STANDARD: 'BEP20',
        ACTUAL_TOKEN_STANDARD: 'BEP20',
        CHAIN: getChainById($config.chainId),
        PAYMENT_TEMPLATE: `ethereum:{TOKEN}@{CHAIN_ID}/transfer?address={TRANSFER_TO}&uint256={AMOUNT}`,
        IS_EVM_BASED: true,
        CHAIN_ID: $config.chainId,
    },
    {
        NAME: 'USDT (Binance Smart Chain)',
        CODE: 'USDT',
        ICON: require('~/assets/images/wallets/usdt.png'),
        GAS_FEE: 0.00126,
        IS_ACTIVE: true,
        TOKEN_STANDARD: 'BEP20',
        ACTUAL_TOKEN_STANDARD: 'BEP20',
        CHAIN: getChainById($config.chainId),
        PAYMENT_TEMPLATE: `ethereum:{TOKEN}@{CHAIN_ID}/transfer?address={TRANSFER_TO}&uint256={AMOUNT}`,
        IS_EVM_BASED: true,
        CHAIN_ID: $config.chainId,
    },
    // {
    //     NAME: 'Cardano',
    //     CODE: 'ADA',
    //     ICON: require('~/assets/images/icons/currencies/ada.png'),
    //     GAS_FEE: 0,
    //     IS_ACTIVE: true,
    //     TOKEN_STANDARD: 'CTS',
    //     CHAIN: null,
    // },
    // {
    //     NAME: 'Ripple',
    //     CODE: 'XRP',
    //     ICON: require('~/assets/images/icons/currencies/xrp.png'),
    //     GAS_FEE: 0,
    //     IS_ACTIVE: true,
    //     TOKEN_STANDARD: 'XRP Ledger,
    //     CHAIN: null,
    // },
    // {
    //     NAME: 'Solana',
    //     CODE: 'SOL',
    //     ICON: require('~/assets/images/icons/currencies/sol.png'),
    //     GAS_FEE: 0,
    //     IS_ACTIVE: true,
    //     TOKEN_STANDARD: 'SPL',
    //     CHAIN: getChainById($config.chainId === 56 ? 101 : 'devnet'),
    // },
    // {
    //     NAME: 'Terra Classic',
    //     CODE: 'LUNC',
    //     ICON: require('~/assets/images/icons/currencies/lunc.png'),
    //     GAS_FEE: 0,
    //     IS_ACTIVE: true,
    //     TOKEN_STANDARD: 'TCS',
    //     CHAIN: getChainById($config.chainId === 56 ? 'columbus-5' : 'bombardment-5'),
    // }
];
export const SUPPORTED_CHAIN_IDS = [56, 97];

export const TRANSACTION_STATUSES = {
    CREATED: 'Pending Transaction',
    PROCESSING: 'Processing',
    PAID: 'Processing',
    FEE_NOT_REFUNDED: 'Processing',
    COMPLETED: 'Completed',
    EXPIRED: 'Expired',
};

export const PRODUCT_TYPES = {
    PARCEL: 'parcel',
    MINER: 'miner',
    BUNDLE: 'bundle',
};

export const WALLET_ERROR_CODES = {
    USER_REJECTED: 4001,
    USER_REJECTED_METAMASK_MOBILE_WALLET_CONNECT: 5000,
    REJECTED_BY_NETWORK: 4100,
    UNRECOGNIZED_CHAIN_ID: 4902,
    UNSUPPORTED_METHOD: -32601,
    TX_ERROR: -32002,
    METAMASK_INTERNAL_ERROR: -32603,
    INVALID_PARAMS: -32602,
    USER_DISAPPROVED_REQUESTED_METHOD: 5001,
    TRANSACTION_EXPIRED: 95000,
};

export const NOTIFICATIONS_FETCH_INTERVAL = 10000; // ms

export const SECONDS_FOR_30_DAYS = 60 * 60 * 24 * 30;

export const DIAG_LOGS_ENABLED = true;
// TODO make use of this
export function diagLog (obj, ...args) {
    if(DIAG_LOGS_ENABLED) {
        console.log(
            `${obj.constructor.name === 'VueComponent' ? obj._name : obj.constructor.name}: `,
            ...args,
        );
    }
}

export const MYSTERY_BOX_ACTIVE = false;
