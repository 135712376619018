import { render, staticRenderFns } from "./ProductOwnerSelection.vue?vue&type=template&id=f29b972e&scoped=true"
import script from "./ProductOwnerSelection.vue?vue&type=script&lang=ts&setup=true"
export * from "./ProductOwnerSelection.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./ProductOwnerSelection.vue?vue&type=style&index=0&id=f29b972e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f29b972e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ComposedFormItemSelect: require('/root/build_gs/core/components/composed/form-item/Select.vue').default,ComposedFormItemInput: require('/root/build_gs/core/components/composed/form-item/Input.vue').default,BaseTextInfoMessage: require('/root/build_gs/core/components/base/text/InfoMessage.vue').default,BaseButtonSimple: require('/root/build_gs/core/components/base/button/Simple.vue').default})
