import { STATE } from "./names";
import { MetaBundle } from "~/core/models/MetaBundle";
import { ParcelStats } from "./types";
import { CashFtCardBundle } from "~/pages/nfts/products/models/CashFtCardBundle";

export default () => {
    return {
        [STATE.BUNDLES_DATA]: [] as MetaBundle[],
        [STATE.BUNDLES_DATA_LOADING]: true,
        [STATE.PARCELS_SLOTS_DATA]: null as ParcelStats | null,
        [STATE.PARCELS_SLOTS_DATA_LOADING]: true,
        [STATE.SINGLE_PARCEL_DATA]: null,
        [STATE.SINGLE_PARCEL_DATA_LOADING]: true,
        [STATE.CARDS_BUNDLES_DATA]: [] as CashFtCardBundle[],
        [STATE.CARDS_BUNDLES_DATA_LOADING]: true,
        [STATE.PARCEL_BUY_POPUP_VISIBLE]: false,
        [STATE.BTC_BUNDLES_DATA]: [] as MetaBundle[],
        [STATE.BTC_BUNDLES_DATA_LOADING]: true,
        [STATE.BTC_SINGLE_PRODUCT_DATA]: [] as MetaBundle[],
        [STATE.BTC_SINGLE_PRODUCT_DATA_LOADING]: true,
        [STATE.BTC_SINGLE_PRODUCT_ACTIVE]: false,
    }
}
