import { MUTATIONS, STATE } from "./names";
import { MetaBundle } from "~/core/models/MetaBundle";
import { ParcelStats, State } from "./types";
import { CashFtCardBundle } from "~/pages/nfts/products/models/CashFtCardBundle";

export default {
    [MUTATIONS.SET_BUNDLES_DATA](state: State, bundles: []) {
        state[STATE.BUNDLES_DATA] = bundles.length ? bundles.map(bundle => new MetaBundle(bundle)) : [];
    },
    [MUTATIONS.SET_BUNDLES_DATA_LOADING](state: State, payload: boolean) {
        state[STATE.BUNDLES_DATA_LOADING] = payload;
    },
    [MUTATIONS.SET_PARCELS_SLOTS_DATA](state: State, payload: ParcelStats) {
        state[STATE.PARCELS_SLOTS_DATA] = payload;
    },
    [MUTATIONS.SET_PARCELS_SLOTS_DATA_LOADING](state: State, payload: boolean) {
        state[STATE.PARCELS_SLOTS_DATA_LOADING] = payload;
    },
    [MUTATIONS.SET_SINGLE_PARCEL_DATA](state: State, payload: any) {
        state[STATE.SINGLE_PARCEL_DATA] = payload ? new MetaBundle(payload) : null;
    },
    [MUTATIONS.SET_SINGLE_PARCEL_DATA_LOADING](state: State, payload: boolean) {
        state[STATE.SINGLE_PARCEL_DATA_LOADING] = payload;
    },
    [MUTATIONS.SET_CARDS_BUNDLES_DATA](state: State, payload: any) {
        state[STATE.CARDS_BUNDLES_DATA] = (payload || []).map((bundle: any) => new CashFtCardBundle(
            bundle.bundle_sc_id,
            bundle.number_of_parcels,
            bundle.is_active,
            bundle.total_purchased,
            bundle.price,
            bundle.minimum_gymnet_deposit,
        ));
    },
    [MUTATIONS.SET_CARDS_BUNDLES_DATA_LOADING](state: State, payload: boolean) {
        state[STATE.CARDS_BUNDLES_DATA_LOADING] = payload;
    },
    [MUTATIONS.SET_PARCEL_BUY_POPUP_VISIBLE](state: State, payload: boolean) {
        state[STATE.PARCEL_BUY_POPUP_VISIBLE] = payload;
    },
    [MUTATIONS.SET_BTC_BUNDLES_DATA](state: State, bundles: []) {
        state[STATE.BTC_BUNDLES_DATA] = bundles.length ? bundles.map(bundle => new MetaBundle(bundle)) : [];
    },
    [MUTATIONS.SET_BTC_BUNDLES_DATA_LOADING](state: State, payload: boolean) {
        state[STATE.BTC_BUNDLES_DATA_LOADING] = payload;
    },
    [MUTATIONS.SET_BTC_SINGLE_PRODUCT_DATA](state: State, bundles: []) {
        state[STATE.BTC_SINGLE_PRODUCT_DATA] = bundles.length ? bundles.map(bundle => new MetaBundle(bundle)) : [];
    },
    [MUTATIONS.SET_BTC_SINGLE_PRODUCT_DATA_LOADING](state: State, payload: boolean) {
        state[STATE.BTC_SINGLE_PRODUCT_DATA_LOADING] = payload;
    },
    [MUTATIONS.SET_BTC_SINGLE_PRODUCT_ACTIVE](state: State, payload: boolean) {
        state[STATE.BTC_SINGLE_PRODUCT_ACTIVE] = payload;
    }
}
