export const STATE = {
    BUY_AUTOSHIP_DATA: 'buyAutoshipData',
} as const;

export const ACTIONS = {
    ON_APPROVE_MUNICIPALITY_TRANSACTION: 'onApproveMunicipalityTransaction',
    ON_START_PURCHASE: 'onStartPurchase',
    ON_SEND_EVENTS: 'onSendEvents',
    ON_PURCHASE_PARCEL: 'onPurchaseParcel',
    ON_PURCHASE_SUPER_BUNDLE: 'onPurchaseSuperBundle',
    ON_PURCHASE_BTC_SINGLE_PRODUCT: 'onPurchaseBtcSingleProduct',
    ON_PURCHASE_AUTOSHIP: 'onPurchaseAutoship',
} as const;

export const MUTATIONS = {
    SET_BUY_AUTOSHIP_DATA: 'setBuyAutoshipData',
} as const;

export const GETTERS = {
    BUY_AUTOSHIP_DATA: 'buyAutoshipData',
} as const;
