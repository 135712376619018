import { render, staticRenderFns } from "./Autoship.vue?vue&type=template&id=e0b02174"
import script from "./Autoship.vue?vue&type=script&setup=true&lang=ts"
export * from "./Autoship.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ComposedCountdownInCard: require('/root/build_gs/core/components/composed/countdown/InCard.vue').default})
