import { render, staticRenderFns } from "./NotificationToggle.vue?vue&type=template&id=3b7ce1a0&scoped=true"
import script from "./NotificationToggle.vue?vue&type=script&setup=true&lang=ts"
export * from "./NotificationToggle.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./NotificationToggle.vue?vue&type=style&index=0&id=3b7ce1a0&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b7ce1a0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseImageSvgThemedIcon: require('/root/build_gs/core/components/base/image/SvgThemedIcon.vue').default,BaseIndicatorBadge: require('/root/build_gs/core/components/base/indicator/Badge.vue').default,BaseButtonSwitcher: require('/root/build_gs/core/components/base/button/Switcher.vue').default})
