import ApiService from "~/core/services/api-interaction/ApiService";
import { ACTIONS, GETTERS, MUTATIONS, STATE } from "./names";
import { ACTIONS as GENERAL_NOTIFICATIONS_ACTIONS } from "../names";
import {
    MUTATIONS as HISTORY_NOTIFICATIONS_MUTATIONS,
    ACTIONS as HISTORY_NOTIFICATIONS_ACTIONS,
    GETTERS as HISTORY_NOTIFICATIONS_GETTERS,
} from "../history/names";
import { ACTIONS as GALLERY_ACTIONS } from "../../gallery/names";
import { ACTIONS as USER_ACTIONS } from "../../auth/names";
import { NotificationTypes } from "../types/NotificationTypes";
import type { RawNotification } from "../types/RawNotification";
import type { Notification } from "../types/Notification";
import type { NotificationHandler } from "./types";
import { POLLING_STORE_FUNCTIONS } from "./polling";

export default {
    ...POLLING_STORE_FUNCTIONS.actions,
    async [ACTIONS.FETCH_PROCESS_AND_SAVE_NOTIFICATIONS_READ_BY_OTHER_SESSIONS]({ dispatch, getters, commit }) {
        if (getters[GETTERS.NOTIFICATIONS_READ_BY_OTHER_SESSIONS_LOADING]) {
            return getters[GETTERS.NOTIFICATIONS_READ_BY_OTHER_SESSIONS_LOADING_PROMISE];
        }
        commit(MUTATIONS.SET_NOTIFICATIONS_READ_BY_OTHER_SESSIONS_LOADING, true);
        let notificationsReadByOtherSessions = null;
        try {
            const loadingPromise = dispatch(ACTIONS.FETCH_AND_PROCESS_NOTIFICATIONS_READ_BY_OTHER_SESSIONS);
            commit(MUTATIONS.SET_NOTIFICATIONS_READ_BY_OTHER_SESSIONS_LOADING_PROMISE, loadingPromise);
            notificationsReadByOtherSessions = await loadingPromise;

            await dispatch(ACTIONS.SAVE_UNREAD_NOTIFICATIONS, notificationsReadByOtherSessions);

            commit(MUTATIONS.SET_NOTIFICATIONS_READ_BY_OTHER_SESSIONS_LOADING_ERROR, null);
        } catch (e) {
            console.warn('Failed to fetch notifications read by other sessions', e);
            commit(MUTATIONS.SET_NOTIFICATIONS_READ_BY_OTHER_SESSIONS_LOADING_ERROR, e);
        } finally {
            commit(MUTATIONS.SET_NOTIFICATIONS_READ_BY_OTHER_SESSIONS_LOADING, false);
            commit(MUTATIONS.SET_NOTIFICATIONS_READ_BY_OTHER_SESSIONS_LOADING_PROMISE, null);
            return notificationsReadByOtherSessions;
        }
    },
    async [ACTIONS.FETCH_AND_PROCESS_NOTIFICATIONS_READ_BY_OTHER_SESSIONS]({ dispatch }) {
        const {
            notifications,
        } = await dispatch(ACTIONS.FETCH_NOTIFICATIONS_READ_BY_OTHER_SESSIONS);
        if (notifications?.length) {
            return dispatch(ACTIONS.PROCESS_UNREAD_NOTIFICATIONS, notifications);
        }
        return [];
    },
    async [ACTIONS.FETCH_NOTIFICATIONS_READ_BY_OTHER_SESSIONS]({ dispatch }) {
        const count = await dispatch(ACTIONS.GET_COUNT_OF_NOTIFICATIONS_READ_BY_OTHER_SESSIONS);
        if (count > 0) {
            return dispatch(
                `affility/user/notifications/history/${HISTORY_NOTIFICATIONS_ACTIONS.FETCH_NOTIFICATION_HISTORY}`,
                { count, offset: 0 },
                { root: true },
            );
        }
        return {
            notifications: [],
        };
    },
    async [ACTIONS.GET_COUNT_OF_NOTIFICATIONS_READ_BY_OTHER_SESSIONS]({ dispatch, getters, rootGetters }) {
        const { total } = await dispatch(
            `affility/user/notifications/history/${HISTORY_NOTIFICATIONS_ACTIONS.FETCH_NOTIFICATION_HISTORY}`,
            { count: 1, offset: 0 },
            { root: true },
        );
        const totalCount = rootGetters[`affility/user/notifications/history/${HISTORY_NOTIFICATIONS_GETTERS.TOTAL_NOTIFICATIONS_COUNT}`];
        return totalCount ? total - totalCount: 0;
    },
    async [ACTIONS.FETCH_PROCESS_AND_SAVE_UNREAD_NOTIFICATIONS](
        { dispatch, commit, getters },
        { save }: {save?: () => boolean} = {},
    ): Promise<Notification[]> {
        if (getters[GETTERS.UNREAD_NOTIFICATIONS_LOADING]) {
            return getters[GETTERS.UNREAD_NOTIFICATIONS_LOADING_PROMISE];
        }
        commit(MUTATIONS.SET_UNREAD_NOTIFICATIONS_LOADING, true);
        let unreadNotifications = null;
        try {
            const loadingPromise = dispatch(ACTIONS.FETCH_AND_PROCESS_UNREAD_NOTIFICATIONS);
            commit(MUTATIONS.SET_UNREAD_NOTIFICATIONS_LOADING_PROMISE, loadingPromise);
            unreadNotifications = await loadingPromise;

            if (!save || save()) {
                await dispatch(ACTIONS.SAVE_UNREAD_NOTIFICATIONS, unreadNotifications);
            }

            commit(MUTATIONS.SET_UNREAD_NOTIFICATIONS_LOADING_ERROR, null);
        } catch (e) {
            console.warn('Failed to fetch unread notifications', e);
            commit(MUTATIONS.SET_UNREAD_NOTIFICATIONS_LOADING_ERROR, e);
        } finally {
            commit(MUTATIONS.SET_UNREAD_NOTIFICATIONS_LOADING, false);
            commit(MUTATIONS.SET_UNREAD_NOTIFICATIONS_LOADING_PROMISE, null);
            return unreadNotifications;
        }
    },
    async [ACTIONS.FETCH_AND_PROCESS_UNREAD_NOTIFICATIONS]({ dispatch }): Promise<Notification[]> {
        const unreadNotifications = await dispatch(ACTIONS.FETCH_UNREAD_NOTIFICATIONS);
        return dispatch(ACTIONS.PROCESS_UNREAD_NOTIFICATIONS, unreadNotifications);
    },
    async [ACTIONS.FETCH_UNREAD_NOTIFICATIONS]({dispatch}): Promise<RawNotification[]> {
        const data = await dispatch(ACTIONS.FETCH_NOTIFICATIONS_READ_BY_OTHER_SESSIONS);
        const unreadNotifications = await ApiService.get('user/notifications/poll');
        return [...unreadNotifications.data, ...data.notifications];
    },
    async [ACTIONS.PROCESS_UNREAD_NOTIFICATIONS](
        { dispatch },
        notifications: RawNotification[],
    ): Promise<Notification[]> {
        const result = await dispatch(
            `affility/user/notifications/${GENERAL_NOTIFICATIONS_ACTIONS.PROCESS_NOTIFICATIONS}`,
            notifications,
            { root: true }
        );
        for (const notification of result) {
            dispatch(ACTIONS.RUN_NOTIFICATION_HANDLERS, notification);
        }
        return result;
    },
    [ACTIONS.SAVE_UNREAD_NOTIFICATIONS]({ commit }, notifications: Notification[]) {
        commit(MUTATIONS.ADD_UNREAD_NOTIFICATIONS, notifications);
        commit(
            `affility/user/notifications/history/${HISTORY_NOTIFICATIONS_MUTATIONS.ADD_TO_TOTAL_NOTIFICATIONS_COUNT}`,
            notifications.length,
            { root: true },
        );
    },
    [ACTIONS.ADD_UNREAD_NOTIFICATIONS_HANDLER]({ commit }, { type, handler }) {
        commit(MUTATIONS.ADD_UNREAD_NOTIFICATIONS_HANDLER, { type, handler });
    },
    [ACTIONS.REMOVE_UNREAD_NOTIFICATIONS_HANDLER]({ commit }, { type, handler }) {
        commit(MUTATIONS.REMOVE_UNREAD_NOTIFICATIONS_HANDLER, { type, handler });
    },
    [ACTIONS.CLEAR_UNREAD_NOTIFICATIONS_HANDLERS]({ commit }) {
        commit(MUTATIONS.CLEAR_UNREAD_NOTIFICATIONS_HANDLERS);
    },
    [ACTIONS.RUN_NOTIFICATION_HANDLERS]({ state, dispatch }, notification: Notification) {
        const type = notification.type;
        if (type === NotificationTypes.IMAGE_APPROVED_NOTIFICATION) {
            dispatch(`affility/user/gallery/${GALLERY_ACTIONS.APPROVE_IMAGE}`, notification.imageId, { root: true });
        } else if(type === NotificationTypes.IMAGE_REJECTED_NOTIFICATION) {
            dispatch(`affility/user/gallery/${GALLERY_ACTIONS.REJECT_IMAGE}`, notification.imageId, { root: true });
        } else if(type === NotificationTypes.ACCOUNT_UPDATED_NOTIFICATION) {
            dispatch(`affility/user/auth/${USER_ACTIONS.UPDATE_USER_AUTH_DATA}`, {
                ...notification.userUpdatedData,
                isEmailVerified: notification.userUpdatedData.is_email_verified,
                emailVerificationDate: notification.userUpdatedData.email_verification_date,
            }, { root: true });
        }
        const handlers = state[STATE.UNREAD_NOTIFICATIONS_HANDLERS][notification.type] || [];
        handlers.forEach((handler: NotificationHandler) => {
            handler(notification);
        });
    },
    [ACTIONS.CLEAR_UNREAD_NOTIFICATIONS_STATE]({ commit, dispatch }) {
        dispatch(ACTIONS.STOP_POLLING);
        commit(MUTATIONS.SET_UNREAD_NOTIFICATIONS, []);
        commit(MUTATIONS.CLEAR_UNREAD_NOTIFICATIONS_HANDLERS);
        commit(MUTATIONS.SET_UNREAD_NOTIFICATIONS_LOADING_ERROR, null);
        commit(MUTATIONS.SET_UNREAD_NOTIFICATIONS_LOADING_PROMISE, null);
        commit(MUTATIONS.SET_UNREAD_NOTIFICATIONS_LOADING, false);
        commit(MUTATIONS.SET_NOTIFICATIONS_READ_BY_OTHER_SESSIONS_LOADING, false);
        commit(MUTATIONS.SET_NOTIFICATIONS_READ_BY_OTHER_SESSIONS_LOADING_ERROR, null);
        commit(MUTATIONS.SET_NOTIFICATIONS_READ_BY_OTHER_SESSIONS_LOADING_PROMISE, null);
    },
};