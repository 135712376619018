import { ACTIONS, MUTATIONS } from "./names";
import {
    EnrollmentTreeTabSelectViewTypes
} from "~/core/types/affility/components/my-team/EnrollmentTreeTabSelectViewTypes";
import ApiService from "~/core/services/api-interaction/ApiService";
import {
    BinaryTreeData,
    EnrollmentTableData,
    EnrollmentTreeData,
    ExtendedRefLinkData
} from "~/store/affility/pages/my-team/types";
import {ACTIONS as AUTH_ACTIONS} from '~/store/affility/user/auth/names';

export default {
    [ACTIONS.ON_MY_TEAM_GUIDE_STEP]({ commit }, value) {
        commit(MUTATIONS.SET_MY_TEAM_GUIDE_STEP, value);
    },
    // ENROLMENT TREE
    [ACTIONS.ON_ENROLLMENT_SELECT_VIEW]({ commit }, value: EnrollmentTreeTabSelectViewTypes) {
        commit(MUTATIONS.SET_ENROLLMENT_SELECT_VIEW, value);
    },
    async [ACTIONS.ON_ENROLLMENT_STATS_DATA]({ commit }) {
        try {
            const response = await ApiService.get(`${this.$config.binaryTreeApi}/api/users/team-statistics`);

            commit(MUTATIONS.SET_ENROLLMENT_STATS_DATA, response.data);
        } catch(e) {
            console.log(e);
        }
    },
    async [ACTIONS.ON_ENROLLMENT_TREE_DATA]({ commit, rootState, dispatch }, payload: any) {
        const currentAuthUserData = rootState?.application?.driver?.authUser;
        let walletAddress = payload || currentAuthUserData.walletAddress;

        try {
            const response = await ApiService.query(`${this.$config.binaryTreeApi}/api/users/my-team-info`, {
                params: { search_query: walletAddress, include_search_user: true }
            });

            if (walletAddress === currentAuthUserData.walletAddress) {
                commit(MUTATIONS.SET_ENROLLMENT_TREE_MAIN_DATA, response.data?.result || []);
            }

            return response.data?.result || [];
        } catch {
            return []
        } finally {
            dispatch(ACTIONS.ON_ENROLLMENT_TREE_LOADER, false);
        }
    },
    async [ACTIONS.ON_ENROLLMENT_TREE_USER_UPDATE](
        { commit, dispatch },
        { walletAddress, userObject }: { walletAddress: string; userObject: EnrollmentTreeData }
    ) {
        if (userObject.user.directPartnersCount === 0) return;

        if (userObject.isCollapsed) {
            commit(MUTATIONS.SET_ENROLLMENT_TREE_USER_UPDATE, { response: [], userObject, isCollapsed: false });
        } else {
            let response = await dispatch(ACTIONS.ON_ENROLLMENT_TREE_DATA, walletAddress);
            commit(MUTATIONS.SET_ENROLLMENT_TREE_USER_UPDATE, { response, userObject, isCollapsed: true });
        }
    },
    [ACTIONS.ON_ENROLLMENT_TREE_RESET]({ commit }) {
        commit(MUTATIONS.SET_ENROLLMENT_TREE_LOADER, true);
        commit(MUTATIONS.SET_ENROLLMENT_TREE_RESET);
    },
    [ACTIONS.ON_ENROLLMENT_TREE_MAIN_USER_RESET]({ commit }) {
        commit(MUTATIONS.SET_ENROLLMENT_TREE_MAIN_USER_RESET);
    },
    [ACTIONS.ON_ENROLLMENT_TREE_LOADER]({ commit }, value: boolean) {
        setTimeout(() => {
            commit(MUTATIONS.SET_ENROLLMENT_TREE_LOADER, value);
        }, 120);
    },
    async [ACTIONS.ON_ENROLLMENT_TREE_SEARCH]({ commit, rootState, dispatch }, searchString: string) {
        const currentAuthUserData = rootState?.application?.driver?.authUser;

        try {
            const response = await ApiService.query(`${this.$config.binaryTreeApi}/api/users/my-team-info`,
                {
                    params: {
                    search_query: searchString || currentAuthUserData.walletAddress,
                    include_search_user: true
                }}
            );

            commit(MUTATIONS.SET_ENROLLMENT_TREE_MAIN_DATA, response.data?.result || []);
        } catch {
            commit(MUTATIONS.SET_ENROLLMENT_TREE_MAIN_DATA, []);
        } finally {
            dispatch(ACTIONS.ON_ENROLLMENT_TREE_LOADER, false);
        }
    },
    [ACTIONS.ON_ENROLLMENT_TABLE_LOADER]({ commit }, value: boolean) {
        commit(MUTATIONS.SET_ENROLLMENT_TABLE_LOADER, value);
    },
    async [ACTIONS.ON_ENROLLMENT_TABLE_DATA](
        { commit, rootState },
        { address, page, perPage }: { address: string, page: number, perPage: number }
    ) {
        const currentAuthUserData = rootState?.application?.driver?.authUser;
        let walletAddress = address || currentAuthUserData.walletAddress;

        try {
            const response = await ApiService.query(`${this.$config.binaryTreeApi}/api/users/my-team-info`, {
                params: { search_query: walletAddress, page, perPage }
            });

            if (walletAddress === currentAuthUserData.walletAddress) {
                commit(MUTATIONS.SET_ENROLLMENT_TABLE_DATA, { response: response.data, page });
            }

            return response.data || [];
        } catch {
            return []
        } finally {
            commit(MUTATIONS.SET_ENROLLMENT_TABLE_LOADER, false);
        }
    },
    [ACTIONS.ON_ENROLLMENT_TABLE_DATA_RESET]({ commit }) {
        commit(MUTATIONS.SET_ENROLLMENT_TABLE_DATA_RESET);
    },
    async [ACTIONS.ON_ENROLLMENT_TABLE_PARTNERS](
        { commit, dispatch },
        { walletAddress, userObject, page, perPage, notCollapse = false }:
            { walletAddress: string; userObject: EnrollmentTableData, page: number, perPage: number, notCollapse: boolean }
    ) {
        if (userObject.user.directPartnersCount === 0) return;

        if (notCollapse) {
            let response = await dispatch(ACTIONS.ON_ENROLLMENT_TABLE_DATA, {
                address: walletAddress, page, perPage
            });
            commit(MUTATIONS.SET_ENROLLMENT_TABLE_PARTNERS, {
                response,
                userObject,
                isCollapsed: true
            });
            return;
        }

        if (userObject.isCollapsed) {
            commit(MUTATIONS.SET_ENROLLMENT_TABLE_PARTNERS, {
                response: { total: 1, result: [] },
                userObject,
                isCollapsed: false
            });
        } else {
            let response = await dispatch(ACTIONS.ON_ENROLLMENT_TABLE_DATA, {
                address: walletAddress, page, perPage
            });
            commit(MUTATIONS.SET_ENROLLMENT_TABLE_PARTNERS, {
                response,
                userObject,
                isCollapsed: true
            });
        }
    },
    async [ACTIONS.ON_ENROLLMENT_TABLE_SEARCH] ({ commit, rootState, state, dispatch }, { searchString, page = 1 }: { searchString: string, page: number }) {
        const currentAuthUserData = rootState?.application?.driver?.authUser;

        const isSearching = searchString && (searchString !== currentAuthUserData.walletAddress && searchString !== currentAuthUserData.username);

        let params: any = { search_query: searchString || currentAuthUserData.walletAddress, page, perPage: 10 };

        if (isSearching) {
            params.include_search_user = true;
        }

        try {
            const response = await ApiService.query(`${this.$config.binaryTreeApi}/api/users/my-team-info`, {
                params
            });

            if (isSearching) {
                commit(MUTATIONS.SET_ENROLLMENT_TABLE_SEARCH, { response: response.data, page: params.page });
            } else {
                commit(MUTATIONS.SET_ENROLLMENT_TABLE_DATA, { response: response.data, page: params.page });
            }
        } catch (error) {
            console.error('Error fetching enrollment table data:', error);
            const mutationName = isSearching ? MUTATIONS.SET_ENROLLMENT_TABLE_SEARCH : MUTATIONS.SET_ENROLLMENT_TABLE_DATA;
            commit(mutationName, {
                response: { result: [], total: 0 },
                page: 1
            });
        } finally {
            commit(MUTATIONS.SET_ENROLLMENT_TABLE_LOADER, false);
        }
    },
    // ENROLMENT TREE

    // CV POINTS HISTORY
    [ACTIONS.ON_CV_POINTS_SEARCH_VALUE]({ commit }, value: string ) {
        commit(MUTATIONS.SET_CV_POINTS_SEARCH_VALUE, value);
    },
    async [ACTIONS.ON_CV_POINTS_DATA]({ commit }, { page, perPage, search = '' }: { page: number; perPage: number, search: string }) {
        commit(MUTATIONS.SET_CV_POINTS_DATA_LOADER, true);

        try {
            const response = await ApiService.query(`${this.$config.binaryTreeApi}/api/statistics/cv-points/history`,
                { params: { page, perPage, search_query: search } }
            );

            commit(MUTATIONS.SET_CV_POINTS_DATA, { data: response.data.data, meta: response.data.meta });
        } catch {
            commit(MUTATIONS.SET_CV_POINTS_DATA, {
                response: {
                    data: [],
                    meta: { page: 1, perPage: 10, total: 0 }
                }
            });
        } finally {
            commit(MUTATIONS.SET_CV_POINTS_DATA_LOADER, false);
        }
    },
    [ACTIONS.ON_CV_POINTS_DATA_LOADER]({ commit }, payload: boolean) {
        commit(MUTATIONS.SET_CV_POINTS_DATA_LOADER, payload);
    },
    [ACTIONS.ON_CV_POINTS_DATA_RESET]({ commit }) {
        commit(MUTATIONS.SET_CV_POINTS_DATA_RESET);
    },
    // CV POINTS HISTORY

    // BINARY TREE
    async [ACTIONS.ON_UPDATE_USER_GUIDE_SEEN]({ dispatch }) {
        try {
            await ApiService.patch(`${this.$config.binaryTreeApi}/api/users`, {
                is_binary_tree_guide_seen: true
            });

            const responseBinary = await ApiService.get(`${this.$config.binaryTreeApi}/api/users`);
            await dispatch(`affility/user/auth/${AUTH_ACTIONS.UPDATE_USER_AUTH_DATA}`, {
                isBinaryTreeGuideSeen: responseBinary?.data?.user?.isBinaryTreeGuideSeen,
            }, { root: true });
        } catch (e) {
            console.error('Error updating user data', e);
        }
    },
    async [ACTIONS.ON_BINARY_TREE_DATA]({ commit }, search: string | null) {
        try {
            const response = await ApiService.get(
                `${this.$config.binaryTreeApi}/api/users/binary-tree`,
                search ? `search/${search}` : ''
            );

            if (!search) {
                commit(MUTATIONS.SET_BINARY_TREE_DATA, response?.data?.subTree);
            }

            return response.data?.subTree;
        } finally {
            commit(MUTATIONS.SET_BINARY_TREE_DATA_LOADER, false);
        }
    },
    async [ACTIONS.ON_BINARY_TREE_USER_UPDATE]({ commit, dispatch }, userObject: BinaryTreeData ) {
        if (userObject.isCollapsed) {
            commit(MUTATIONS.SET_BINARY_TREE_USER_UPDATE, { response: null, userObject, isCollapsed: false });
        } else {
            const response = await dispatch(ACTIONS.ON_BINARY_TREE_DATA, userObject.user.binaryPath);
            commit(MUTATIONS.SET_BINARY_TREE_USER_UPDATE, { response, userObject, isCollapsed: true });
        }
    },
    [ACTIONS.ON_BINARY_TREE_RESET]({ commit }) {
        commit(MUTATIONS.SET_BINARY_TREE_DATA_LOADER, true);
        commit(MUTATIONS.SET_BINARY_TREE_RESET);
    },
    async [ACTIONS.ON_BINARY_TREE_SEARCH]({ commit, dispatch }, search: string) {
        try {
            const response = await dispatch(ACTIONS.ON_BINARY_TREE_DATA, search);
            commit(MUTATIONS.SET_BINARY_TREE_DATA, response);
        } catch {
            commit(MUTATIONS.SET_BINARY_TREE_DATA, null);
        }
    },
    [ACTIONS.ON_EXTENDED_REFERRAL_LINK_DATA]({ commit }, { visible, username, leg }: ExtendedRefLinkData) {
        commit(MUTATIONS.SET_EXTENDED_REFERRAL_LINK_DATA, { visible, username, leg });
    },
    async [ACTIONS.ON_CHECK_USERNAME]({ _ }, username: string) {
        try {
            const usernameResult = await ApiService.query(`${this.$config.binaryTreeApi}/api/referr/is-referral-by-username`, {
                params: { username },
            });

            return !!usernameResult?.data?.result;
        } catch {
            return false;
        }
    },
    async [ACTIONS.ON_CREATE_REF_LINK]({ _ }, { username, leg }: { username: string, leg: string }) {
        try {
            const usernameResult = await ApiService.query(`${this.$config.binaryTreeApi}/api/referr/get-link`, {
                params: { username, leg },
            });

            return usernameResult?.data?.result;
        } catch {
            return '';
        }
    },
    async [ACTIONS.ON_BINARY_TREE_DATA_LEFT_RIGHT]({ commit }, lastLeg: 'last-left' | 'last-right') {
        try {
            const response = await ApiService.get(`${this.$config.binaryTreeApi}/api/users/binary-tree`, lastLeg);

            commit(MUTATIONS.SET_BINARY_TREE_DATA, response?.data?.subTree);
        } finally {
            commit(MUTATIONS.SET_BINARY_TREE_DATA_LOADER, false);
        }
    },
    // BINARY TREE

    [ACTIONS.CLEAR_MY_TEAM_DATA]({ commit }) {
        commit(MUTATIONS.SET_CLEAR_ENROLLMENT_STATS_DATA);
        commit(MUTATIONS.SET_MY_TEAM_GUIDE_STEP, '');
    },
}
