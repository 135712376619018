import { render, staticRenderFns } from "./WrongWallet.vue?vue&type=template&id=9eca7864"
import script from "./WrongWallet.vue?vue&type=script&lang=ts&setup=true"
export * from "./WrongWallet.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./WrongWallet.vue?vue&type=style&index=0&id=9eca7864&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseImageSvgThemedIcon: require('/root/build_gs/core/components/base/image/SvgThemedIcon.vue').default,ComposedActionCopyToClipboard: require('/root/build_gs/core/components/composed/action/CopyToClipboard.vue').default,FlowsPurchaseComponentsPurchaseSummaryInfoList: require('/root/build_gs/core/components/flows/purchase/components/purchase-summary/InfoList.vue').default,BaseButtonSimple: require('/root/build_gs/core/components/base/button/Simple.vue').default,ComposedPopupCloseButtonAndHeading: require('/root/build_gs/core/components/composed/popup/CloseButtonAndHeading.vue').default})
