import { render, staticRenderFns } from "./PapPurchaseSummary.vue?vue&type=template&id=cf553796"
import script from "./PapPurchaseSummary.vue?vue&type=script&lang=ts&setup=true"
export * from "./PapPurchaseSummary.vue?vue&type=script&lang=ts&setup=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FlowsPurchaseComponentsPurchaseSummaryProductImage: require('/root/build_gs/core/components/flows/purchase/components/purchase-summary/ProductImage.vue').default,FlowsPurchaseComponentsPurchaseSummaryTotal: require('/root/build_gs/core/components/flows/purchase/components/purchase-summary/Total.vue').default,BaseButtonSimple: require('/root/build_gs/core/components/base/button/Simple.vue').default})
