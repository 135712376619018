

export enum CashFTCardStatus {
    WAITING = 'waiting',
    PENDING = 'pending',
    ATTACHED = 'attached',
    ACTIVATED = 'activated',
    DIACTIVATED = 'diactivated',
    REPLACED = 'replaced',
}

export class CashFTCard {
    id: number;
    cardId: number;
    cardType: string;
    cardStatus: CashFTCardStatus;
    lastDigits: string;
    cardName: string;
    cardImage: string;
    cardKitNumber: string;
    cardPin: boolean;

    constructor(data: any) {
        this.id = data.id
        this.cardId = data.card_id;
        this.cardType = data.card_type;
        this.cardStatus = data.status;
        this.lastDigits = data.last_digits;
        this.cardKitNumber = data.card_kit_number;
        this.cardPin = data.card_pin;

        switch (data.card_type) {
            case "plastic":
                this.cardName = "Explorer";
                break;
            case "metal":
                this.cardName = "Mastery";
        }

        const name = this.cardName?.toLowerCase();
        this.cardImage = require(`@/assets/images/icons/cashFT/cashft-${name}-card.webp`);
    }

    get isActivated() {
        return this.cardStatus === CashFTCardStatus.ACTIVATED;
    }

    get isDeactivated() {
        return this.cardStatus === CashFTCardStatus.DIACTIVATED;
    }

    get isReplaced() {
        return this.cardStatus === CashFTCardStatus.REPLACED;
    }

    get isPending() {
        return this.cardStatus === CashFTCardStatus.PENDING || this.cardStatus === CashFTCardStatus.WAITING;
    }

    get isWaiting() {
        return this.cardStatus === CashFTCardStatus.WAITING;
    }

    setStatus(status: CashFTCardStatus) {
        this.cardStatus = status;
    }
}
