import { render, staticRenderFns } from "./Total.vue?vue&type=template&id=9f32576e&scoped=true"
import script from "./Total.vue?vue&type=script&lang=ts&setup=true"
export * from "./Total.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./Total.vue?vue&type=style&index=0&id=9f32576e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9f32576e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseImageSvgThemedIcon: require('/root/build_gs/core/components/base/image/SvgThemedIcon.vue').default})
