import { render, staticRenderFns } from "./ExternalWalletMissingAmount.vue?vue&type=template&id=6403d4eb&scoped=true"
import script from "./ExternalWalletMissingAmount.vue?vue&type=script&lang=ts&setup=true"
export * from "./ExternalWalletMissingAmount.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./ExternalWalletMissingAmount.scss?vue&type=style&index=0&id=6403d4eb&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6403d4eb",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseImageSvgThemedIcon: require('/root/build_gs/core/components/base/image/SvgThemedIcon.vue').default,ComposedActionCopyToClipboard: require('/root/build_gs/core/components/composed/action/CopyToClipboard.vue').default,FlowsPurchaseComponentsPurchaseSummaryInfoList: require('/root/build_gs/core/components/flows/purchase/components/purchase-summary/InfoList.vue').default})
