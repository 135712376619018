import type { SidebarConfigReturnType, SidebarItem } from "./types";
import { METAVERSES, METAVERSE_IDS } from "~/core/services/utils/MetaverseConstants";
import { GETTERS, STATE } from "./names";
import { GETTERS as AUTH_USER_GETTERS } from "../../user/auth/names";
import { GETTERS as METAVERSE_GETTERS } from "../../metaverse/names";

export default {
    [GETTERS.OPENED_SUBMENU]: state => state[STATE.OPENED_SUBMENU],
    [GETTERS.MOBILE_SIDEBAR_OPEN]: state => state[STATE.MOBILE_SIDEBAR_OPEN],
    [GETTERS.ACTIVE_ITEM_PATH]: state => state[STATE.ACTIVE_ITEM_PATH],
    [GETTERS.MOBILE_PROFILE_SIDEBAR_OPEN]: state => state[STATE.MOBILE_PROFILE_SIDEBAR_OPEN],
    [GETTERS.MOBILE_CONTRACT_ADDRESSES_SIDEBAR_OPEN]: state => state[STATE.MOBILE_CONTRACT_ADDRESSES_SIDEBAR_OPEN],
    [GETTERS.MOBILE_LANGUAGES_SIDEBAR_OPEN]: state => state[STATE.MOBILE_LANGUAGES_SIDEBAR_OPEN],
    [GETTERS.CONFIG]: (_state, _getters, _rootState, rootGetters): SidebarConfigReturnType => {
        const user = rootGetters[`affility/user/auth/${AUTH_USER_GETTERS.CURRENT_USER}`];
        const metaverseId = rootGetters[`affility/metaverse/${METAVERSE_GETTERS.ID}`];

        return [
            // {
            //     title: "Metaverses",
            //     to: "/metaverse-switch",
            //     prefixIcon: {
            //         activatable: true,
            //         name: 'metaverses',
            //         path: 'activatable',
            //     },
            // },
            {
                title: "Dashboard",
                to: "/dashboard",
                prefixIcon: {
                    activatable: true,
                    name: 'dashboard',
                    path: 'activatable',
                },
                invisible: !user,
            },
            {
                title: "Finance",
                prefixIcon: {
                    activatable: true,
                    name: 'wallet',
                    path: 'activatable',
                },
                invisible: !user,
                children: [
                    {
                        title: "My wallet",
                        to: "/finances/wallet",
                    },
                    {
                        title: "Minting Rewards",
                        to: "/finances/miner-rewards",
                    },
                    {
                        title: "Commissions",
                        to: "/finances/commissions",
                        invisible: !user?.isReferralLinkActive,
                    },
                    {
                        title: "Single pool",
                        to: "/finances/single-pool",
                    },
                ],
            },
            {
                title: "Products",
                invisible: !user,
                prefixIcon: {
                    activatable: true,
                    name: 'products',
                    path: 'activatable',
                },
                children: [
                    {
                        title: "My Products",
                        to: "/nfts/my-nfts",
                        invisible: !user,
                    },
                    {
                        title: "Cards",
                        to: "/nfts/cards",
                        invisible: !user || metaverseId !== METAVERSE_IDS.GYMSTREET,
                    },
                ],
            },
            {
                title: "Shop",
                to: "/shop",
                prefixIcon: {
                    activatable: true,
                    name: 'marketplace',
                    path: 'activatable',
                },
            },
            {
                title: "Promos",
                prefixIcon: {
                    activatable: true,
                    name: 'promo',
                    path: 'activatable',
                },
                invisible: !user || (
                                        !user?.hasAccessToPartnerArea() &&
                                        (!user?.isUsedFastStartIncentive || user?.isRegisteredAfterMarch4)
                                    ),
                children: [
                    {
                        title: "Global Pool",
                        to: "/partner-area/incentives/global-pool",
                        invisible: !user?.hasAccessToPartnerArea(),
                    },
                    {
                        title: "Product Autoship",
                        to: "/partner-area/incentives/product-autoship-incentive",
                        invisible: !user?.hasAccessToPartnerArea(),
                    },
                    {
                        title: "3rd Anniversary",
                        to: "/partner-area/incentives/third-anniversary-promo",
                        invisible: !user?.hasAccessToPartnerArea(),
                    },
                    {
                        title: "Direct Sales",
                        to: '/partner-area/incentives/direct-sales-incentive',
                        invisible: !user?.hasAccessToPartnerArea(),
                    },
                    // {
                    //     title: "Travel",
                    //     to: "/partner-area/incentives/travel-incentive",
                    //     invisible: !user?.hasAccessToPartnerArea() ||
                    //                 metaverseId !== METAVERSE_IDS.GYMSTREET,
                    // },
                    // {
                    //     title: "Turnover",
                    //     to: "/partner-area/incentives/turnover-incentive",
                    //     invisible: !user?.hasAccessToPartnerArea() ||
                    //                 metaverseId !== METAVERSE_IDS.GYMSTREET,
                    // },
                    {
                        title: "Fast Start",
                        to: "/partner-area/incentives/fast-start",
                        invisible: !user?.isUsedFastStartIncentive || user?.isRegisteredAfterMarch4,
                    },
                    // {
                    //     title: "Spring",
                    //     to: "/partner-area/incentives/spring-incentive",
                    //     invisible: !user?.hasAccessToPartnerArea(),
                    // },
                    // {
                    //     title: "Cash Promo",
                    //     to: "/partner-area/incentives/cash-promo",
                    //     invisible: !user?.hasAccessToPartnerArea(),
                    // },
                    // {
                    //     title: "Level pool",
                    //     to: "/partner-area/level-pool",
                    //     invisible: !user?.hasAccessToPartnerArea(),
                    // },
                    // {
                    //     title: "Turnover pool",
                    //     to: "/partner-area/turnover-pool",
                    //     invisible: !user?.hasAccessToPartnerArea(),
                    // },
                    // {
                    //     title: "Global Line Bonus",
                    //     to: "/partner-area/global-line-bonus",
                    //     invisible: !user?.hasAccessToPartnerArea(),
                    // },
                    // {
                    //     title: "Mystery Box",
                    //     to: "/promos/mystery-box",
                    //     invisible: metaverseId !== METAVERSE_IDS.GYMSTREET,
                    // },
                ],
            },
            {
                title: "Map",
                prefixIcon: {
                    activatable: true,
                    name: 'map',
                    path: 'activatable',
                },
                children: [
                    {
                        title: `${METAVERSES[metaverseId].metaverse_name} Map`,
                        to: "/metaworld/map",
                    },
                    {
                        title: "Gallery",
                        to: "/metaworld/gallery",
                        invisible: !user,
                    },
                ],
            },
            {
                title: "Partnerships",
                to: "/partnerships",
                prefixIcon: {
                    activatable: true,
                    name: 'partnership',
                    path: 'activatable',
                },
            },

            {
                title: "Career Status",
                to: "/partner-area/career-status",
                prefixIcon: {
                    activatable: true,
                    name: 'career-status',
                    path: 'activatable',
                },
                invisible: !user?.hasAccessToPartnerArea()
            },
            {
                title: "My Team",
                to: "/my-team",
                prefixIcon: {
                    activatable: true,
                    name: 'my-team',
                    path: 'activatable',
                },
                invisible: !user?.hasAccessToPartnerArea()
            },
            // {
            //     title: "NFT Income",
            //     to: "/partner-area/NFT-Commissions",
            //     icon: "NFT-Commissions",
            //     svgIconHover: "NFT-Commissions-hover",
            //     invisible: () => !!user
            // },
            // {
            //     title: "Leadership Incentives",
            //     to: "/partner-area/nft-rank-rewards",
            //     icon: "NFT-Commissions",
            //     svgIconHover: "NFT-Commissions-hover",
            //     invisible: () => !!user
            // },
        ];
    },
}
