import {DEFAULT_USER_AVATAR_PATH} from "~/core/services/utils/ImageConstants";
import {USER_PERMISSION_TYPES, EMAIL_VERIFICATION_DAYS, SECONDS_FOR_30_DAYS} from "~/core/services/utils/Constants";
import {MetaWorldManager} from "~/core/services/map/MetaWorldManager";
import {RANKS_ORDER} from "~/constants/constants";

export class MetaUser {
    constructor (jsonData) {
        this.id = jsonData.id;
        this.gymnetWalletId = jsonData.gymnet_wallet_id;
        this.email = jsonData.email;
        this.walletAddress = jsonData.wallet_address;
        this.firstName = jsonData.first_name;
        this.lastName = jsonData.last_name;
        this.utcOffset = jsonData.utc_offset;
        this.isEmailVerified = jsonData.is_email_verified;
        this.createdAt = new Date(jsonData.created_at);
        this.createdBy = jsonData.created_by;
        this.updatedAt = new Date(jsonData.updated_at);
        this.updatedBy = jsonData.updated_by;
        this.emailVerificationDate = jsonData.email_verification_date;
        this.avatar = jsonData.avatar ? jsonData.avatar : DEFAULT_USER_AVATAR_PATH;
        this.username = jsonData.username;
        this.userAlertType = jsonData.notification_type;
        this.isBlocked = jsonData.is_blocked;
        this.isDeleted = jsonData.is_deleted;
        this.role = jsonData.role;
        this.settings = jsonData.settings;
        this.utcOffset = jsonData.utc_offset;
        this.lastLoginTime = jsonData.last_login_time;
        this.referralId = jsonData.referral_id;
        this.referralLink = jsonData.referral_link;
        this.referralAddress = jsonData.referral_address;
        this.company = jsonData.company;
        this.companyUrl = jsonData.company_url;
        this.isGlobalTmsConfirmed = jsonData.is_global_tms_confirmed;
        this.isAffiliateTmsConfirmed = jsonData.is_affiliate_tms_confirmed;
        this.referrerCode = jsonData.referrer_code;
        this.hasPurchasedNFT = jsonData.has_purchased_nft;
        this.userPurchases = null;
        this.walletAddress = jsonData.wallet_address;
        this.relatedWalletAddress = jsonData.related_wallet_address ? jsonData.related_wallet_address : jsonData.wallet_address;
        this.lastEmailSent = jsonData.last_email_sent;
        this.emailNotVerifiedSince = new Date(jsonData.email_not_verified_since);
        this.isVerified = false;
        this.loginOptions = jsonData.login_options ? jsonData.login_options.map(lo => lo.login_type) : [];
        this.isUMUser = jsonData.is_um_user;
        this.isFullyWeb2User = Boolean(jsonData.is_fully_web2);
        this.importedTokens = jsonData.imported_tokens;
        this.city = jsonData.city;
        this.country = jsonData.country;
        this.nationality = jsonData.nationality;
        this.zip = jsonData.zip;
        this.street = jsonData.street;
        this.bitopexReferalId = jsonData.bitopex_referal_id;
        this.username = jsonData.username;
        this.usernameLastUpdated = jsonData.username_last_updated_at;
        this.isCashFtTermsAndConditionsAccepted = jsonData?.cashft_terms;
        this.isRoleSelected = jsonData?.binaryUser?.user?.isRoleSelected;
        this.currentRankName = jsonData.binaryUser?.user?.currentRank;
        this.commissionRankName = jsonData.binaryUser?.user?.commissionRank;
        this.subscriptionExpiresAt = jsonData?.binaryUser?.subscription_expires_at;
        this.isReferralLinkActive = jsonData?.binaryUser?.user?.isReferralLinkActive;
        this.leftLegReffer = jsonData.binaryUser?.left_leg_referr;
        this.rightLegReffer = jsonData.binaryUser?.right_leg_referr;
        this.isFastStartIncentiveActive = jsonData.binaryUser?.user?.isFastStartIncentiveActive;
        this.binaryUserRegistrationDate = jsonData.binaryUser?.user?.createdAt;
        this.totalOwnPurchaseAmount = jsonData.binaryUser?.user?.totalOwnPurchaseAmount;
        this.totalUnilevelTeamTurnoverAmount = jsonData?.binaryUser?.user?.totalUnilevelTeamTurnoverAmount;
        this.totalTeamCvPoints = jsonData?.binaryUser?.user?.totalTeamCvPoints;
        this.sponsorWalletAddress = jsonData?.binaryUser?.sponsorWalletAddress;
        this.activeSubscriptionAbonement = jsonData?.binaryUser?.user?.activeSubscriptionAbonement;
        this.isUsedFastStartIncentive = jsonData?.binaryUser?.user?.isUsedFastStartIncentive;
        this.isBinaryTreeGuideSeen = jsonData?.binaryUser?.user?.isBinaryTreeGuideSeen;
        this.firstPurchaseDate = jsonData?.binaryUser?.firstPurchaseDate;
        this.frozenAutoship = 0;
        this.isEnableTwoFactor = jsonData?.is_enable_two_factor;
        this.isRankUpdateNotified = jsonData?.binaryUser?.user?.isRankUpgradeNotificationSeen;
    }

    selfUpdate (data) {
        for (const [key, value] of Object.entries(data)) {
            if (key === 'commissionRankName') {
                if (RANKS_ORDER[value] > RANKS_ORDER[this[key]] || (!this.commissionRankName && value)) {
                    this.commissionRankName = value;
                }
            } else {
                this[key] = value;
            }
        }
    }

    hasAccessToPlatform () {
        return this.isGlobalTmsConfirmed;
    }

    get needsToConfirmEmail () {
        if (this.isEmailVerified){
            return false;
        }
        const now = new Date();
        const creationDate = this.emailNotVerifiedSince;
        const diff = now.getTime() - creationDate.getTime();
        const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
        return this.email && diffDays <= 3 && !this.isEmailVerified;
    }

    get emailConfirmationExpiration() {
        const emailNotVerifiedSince = new Date(this.emailNotVerifiedSince);
        emailNotVerifiedSince.setDate(emailNotVerifiedSince.getDate() + EMAIL_VERIFICATION_DAYS);

        return emailNotVerifiedSince;
    }

    get emailConfirmationOverdued () {
        const now = new Date();
        const creationDate = this.emailNotVerifiedSince;
        const diff = now.getTime() - creationDate.getTime();
        const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
        return diffDays > EMAIL_VERIFICATION_DAYS;
    }

    hasAccessToPartnerArea () {
        return this.isReferralLinkActive;
    }

    async getReferrerCode () {
        // const referrerAddress = await MetaWorldManager.sharedInstance().contracts.MLM.methods.userToReferrer(this.walletAddress ?? this.relatedWalletAddress).call();
        const referrerId = await MetaWorldManager.sharedInstance().contracts.MLM.methods.addressToId(this.sponsorWalletAddress).call();
        return Number(referrerId);
    }

    can (permission) {
        switch (permission) {
            case USER_PERMISSION_TYPES.BUY_MINER:
                return true;
                return this.userPurchases && this.userPurchases >= 100;
            case USER_PERMISSION_TYPES.CLAIM_FROM_BALANCE:
                return true;
                return this.isVerifiedUser();
        }
    }

    isWeb2User () {
        return !this.walletAddress;
    }

    setVerified () {
        this.isVerified = true;
    }

    isVerifiedUser () {
        return this.walletAddress && this.isVerified;
    }

    get canUpdateUsername () {
        if (!this.usernameLastUpdated) {
            return true;
        }
        const now = new Date();
        const lastUpdate = new Date(this.usernameLastUpdated);
        const diff = now.getTime() - lastUpdate.getTime();
        const diffDays = Math.ceil(diff / (1000 * 3600 * 24));
        return diffDays >= 180;
    }

    get isFastStartAvailable () {
        const fastStartDate = new Date(this.createdAt).getTime() + SECONDS_FOR_30_DAYS * 1000;
        return new Date() < fastStartDate;
    }

    get isNFTTurboAvailable () {
        const fastStartDate = new Date(this.binaryUserRegistrationDate).getTime() + SECONDS_FOR_30_DAYS * 1000;
        return new Date() < fastStartDate;
    }

    get fastStartIncentiveDate() {
        const userCreatedAt = this.binaryUserRegistrationDate ? +new Date(this.binaryUserRegistrationDate) : 0;
        let date;

        date = (userCreatedAt + (30 * 24 * 60 * 60 * 1000));
        return date ? date : 0;
    }

    get sixNFTTurboExpiration () {
        const sixDayIncentiveEndTimestamp = new Date(this.binaryUserRegistrationDate).getTime() + SECONDS_FOR_30_DAYS * 1000;
        return new Date(sixDayIncentiveEndTimestamp);
    }

    get isRegisteredAfterMarch4() {
        const MARCH_4_2024 = 1709550000000;
        var userRegistrationTimestamp = new Date(this.binaryUserRegistrationDate).getTime(); // Convert to timestamp

        return userRegistrationTimestamp >= MARCH_4_2024;
    }

    get anyWalletAddress () {
        return this.walletAddress || this.relatedWalletAddress;
    }

    get isAutoshipFree() {
        return RANKS_ORDER[this.commissionRankName] < 5;
    }

    get remindTwoFactorAuthentication() {
        return this.isEmailVerified && typeof this.isEnableTwoFactor !== 'boolean';
    }

    get is2FAEnabled() {
        return !!this.isEnableTwoFactor;
    }
}


