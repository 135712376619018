import { render, staticRenderFns } from "./UseExtraBalance.vue?vue&type=template&id=486b1402&scoped=true"
import script from "./UseExtraBalance.vue?vue&type=script&lang=ts&setup=true"
export * from "./UseExtraBalance.vue?vue&type=script&lang=ts&setup=true"
import style0 from "./UseExtraBalance.vue?vue&type=style&index=0&id=486b1402&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "486b1402",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseImageSvgThemedIcon: require('/root/build_gs/core/components/base/image/SvgThemedIcon.vue').default,BaseChipsSimple: require('/root/build_gs/core/components/base/chips/Simple.vue').default,BaseInputTemplate: require('/root/build_gs/core/components/base/input/Template.vue').default})
