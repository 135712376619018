import {
    diagLog,
    MINER_HASH_POWER,
    NULLABLE_WALLET_ADDRESS,
    PARCEL_UPGRADED_MINERS_COUNT,
    MINER_UNIT_PRICE,
    PARCEL_MINERS_COUNT,
    WALLET_ERROR_CODES,
} from '../utils/Constants';
import ApiService from "../api-interaction/ApiService";
import { MetaNotificationsContainer } from "../notifications/MetaNotificationsContainer";
import { MetaInventoryContainer } from "~/core/models/MetaInventoryContainer";
import { GlobalMakerService } from "~/core/services/GlobalMakerService";
import { PopupHelper } from "~/core/helpers/PopupHelper";
import { TranslationHelper } from "~/core/services/TranslationHelper";
import { addAndPromisifyBatchCalls } from "~/core/helpers/GlobalHelpers";
import { TxRejectedByUserException } from "~/core/exceptions/TxRejectedByUserException";
import { FileCoinService } from '~/pages/finances/wallet/services/FileCoinService';
import { SubscriptionService } from '~/pages/finances/wallet/services/SubscriptionService';
import { EthService } from '~/pages/finances/wallet/services/EthService';
import { getParcelInfoContractByMetaverseId } from "~/store/affility/pages/shop/bundleConfig";
import { ACTIONS as USER_FEEDBACK_ACTIONS } from "~/store/affility/user/feedback/modals/full-loader/names";
import { ACTIONS as AUTH_ACTIONS } from '~/store/affility/user/auth/names';
import { ACTIONS as ACTIONS_USER_FEEDBACK } from "~/store/affility/user/feedback/names";
import { ACTIONS as TOAST_ACTIONS } from "~/store/affility/user/feedback/modals/toast-notification/names";

const GAS_FEE_MULTIPLIER = 1.5;

export class MetaWorldManager {
    static sharedInstance () {
        if(MetaWorldManager.instance === null) {
            MetaWorldManager.instance = new MetaWorldManager();
        }
        return MetaWorldManager.instance;
    }

    constructor () {
        this.metaverseId = null;
        this.allContracts = null;
        this.allWriteContracts = null;
        this.fetchUserGoodsLoop = null;
        this.bundlesCache = new Map();
    }

    get contracts () {
        return this.allContracts?.[this.metaverseId];
    }

    get writeContracts () {
        return this.allWriteContracts?.[this.metaverseId];
    }

    async fetchUserBalanceFromContract (contractName, batchRequest = null) {
        let balance = '0';
        const address = this.getWalletAddressForFetchInfo();
        if(address && this.contracts) {
            if(!batchRequest) {
                balance = this.readWeb3().utils.fromWei(await this.contracts[contractName].methods.balanceOf(address).call());
            } else {
                const balanceCall = {method: this.contracts[contractName].methods.balanceOf(address).call, name: contractName};
                const { resolvedValues, promisesArray } = addAndPromisifyBatchCalls(batchRequest, [balanceCall]);

                await Promise.all(promisesArray);
                balance = this.readWeb3().utils.fromWei(resolvedValues[contractName]);
            }
        }
        return Number(balance).toFixed(4);
    }

    async fetchUserCryptoBalance (batchRequest = null) {
        const result = {
            busd: 0,
            gymnet: 0,
            subscriptionPoints: 0,
            usdt: 0,
            filecoinNative: 0,
            eth: 0,
            moonberg: 0,
        };

        try {
            const address = this.getWalletAddressForFetchInfo();
            const busd = this.fetchUserBalanceFromContract('BUSD', batchRequest);
            const usdt = this.fetchUserBalanceFromContract('USDT', batchRequest);
            const gymnet = this.fetchUserBalanceFromContract('GymNetwork', batchRequest);
            const subscriptionPoints = SubscriptionService.getSubscriptionPoints();
            const filecointNative = FileCoinService.getNativeBalance(address);
            const eth = EthService.getNativeBalance(address);
            const moonberg = EthService.getMoonbergBalance(address);
            const btc = this.fetchUserBalanceFromContract('BTCB', batchRequest);

            const mapEntries = [
                [busd, 'busd'],
                [gymnet, 'gymnet'],
                [subscriptionPoints, 'subscriptionPoints'],
                [usdt, 'usdt'],
                [filecointNative, 'filecoinNative'],
                [eth, 'eth'],
                [moonberg, 'moonberg'],
                [btc, 'btc'],
            ];

            const promisesMap = new Map(mapEntries);

            const resolvedValues = await Promise.allSettled(promisesMap.keys());

            let errorInnerText = '';

            for (let i = 0; i < resolvedValues.length; i++) {
                result[mapEntries[i][1]] = resolvedValues[i].status === 'fulfilled' ? Number(resolvedValues[i].value) : null;
                if (resolvedValues[i].status !== 'fulfilled') {
                    errorInnerText += mapEntries[i][1] + '(' + resolvedValues[i].reason + '), ';
                }
            }

            if (errorInnerText) {
                let errorTxt = `Balance for [${errorInnerText}] have thrown an error`;
                throw new Error(errorTxt);
            }
        } catch (e) {
            console.log(e);
        }
        MetaInventoryContainer.sharedInstance().setUserCryptoBalance(result);
        return result;
    }

    getWalletAddressForFetchInfo (defaultWallet = null, userToGet = null) {
        const authUser = userToGet ?? MetaInventoryContainer.sharedInstance().authUserData;
        let address = defaultWallet ?? NULLABLE_WALLET_ADDRESS;
        if (authUser) {
            if (authUser.walletAddress) {
                address = authUser.walletAddress;
            } else if (authUser.relatedWalletAddress) {
                address = authUser.relatedWalletAddress;
            }
        }

        return address;
    }

    async fetchUserOwnedNFTsSum () { // TODO check usages of this method and refactor
        const authUser = MetaInventoryContainer.sharedInstance().authUserData;
        const userAddress = authUser?.walletAddress;
        if(userAddress) {
            const p = await this.contracts.StandardParcel.methods.balanceOf(userAddress).call();
            const m = await this.contracts.MinerNFT.methods.balanceOf(userAddress).call();
            return Number(p) + Number(m);
        }
        return 0;
    }

    async fetchParcelPriceInfo (parcelCount) {
        let result = null;
        const paymentType = 10;
        try {
            const priceInfo = await this.contracts.Municipality.methods.getPriceForPurchaseParcels(parcelCount, paymentType).call();

            result = {
                priceForMintParcel: parseFloat(this.web3().utils.fromWei(priceInfo[0])),
                priceForBuyParcel: parseFloat(this.web3().utils.fromWei(priceInfo[1])),
            };
        } catch (e) {
            console.error('Failed to fetch parcel price info', e);
        }

        return result;
    }

    async fetchUserBalance (batchRequest = null) {
        let miners = 0;
        let parcels = 0;
        let upgrades = 0;
        const address = this.getWalletAddressForFetchInfo();
        if(batchRequest) {
            const { resolvedValues, promisesArray } = addAndPromisifyBatchCalls(batchRequest, [{
                method: this.contracts.Municipality.methods.usersMintableNFTAmounts(address).call,
                name: 'userBalance',
            }]);
            await Promise.all(promisesArray);
            ({miners, parcels, upgrades} = resolvedValues.userBalance);
        } else {
            ({miners, parcels, upgrades} = await this.contracts.Municipality.methods.usersMintableNFTAmounts(address).call());
        }
        MetaInventoryContainer.sharedInstance().setUserBalance({miners, parcels, upgrades});
    }

    async fetchUserPurchases (batchRequest = null) {
        let userPurchases = 0;
        const GS_METAVERSE = 1;
        const KB_METAVERSE = 2;
        const address = this.getWalletAddressForFetchInfo();

        if(batchRequest) {
            const { resolvedValues: resolvedValuesGS, promisesArray: promisesArrayGS } = addAndPromisifyBatchCalls(batchRequest, [{
                method: this.allContracts?.[GS_METAVERSE].Municipality.methods.userToPurchasedAmountMapping(address).call,
                name: 'userPurchases',
            }]);

            const { resolvedValues: resolvedValuesKB, promisesArray: promisesArrayKB } = addAndPromisifyBatchCalls(batchRequest, [{
                method: this.allContracts?.[KB_METAVERSE].Municipality.methods.userToPurchasedAmountMapping(address).call,
                name: 'userPurchases',
            }]);

            await Promise.all([...promisesArrayGS, ...promisesArrayKB]);

            userPurchases = parseFloat(this.web3().utils.fromWei(resolvedValuesGS.userPurchases)) + parseFloat(this.web3().utils.fromWei(resolvedValuesKB.userPurchases));
        } else {
            const results = await Promise.all([
                this.allContracts?.[GS_METAVERSE].Municipality.methods.userToPurchasedAmountMapping(address).call(),
                this.allContracts?.[KB_METAVERSE].Municipality.methods.userToPurchasedAmountMapping(address).call()
            ])
            userPurchases = parseFloat(this.web3().utils.fromWei(results[0])) + parseFloat(this.web3().utils.fromWei(results[1]));
        }
        await GlobalMakerService.$store.dispatch(`affility/user/auth/${AUTH_ACTIONS.UPDATE_USER_AUTH_DATA}`, {userPurchases});
    }

    setUserGoodsFetchingLoop () {

        if(this.fetchUserGoodsLoop) {
            this.stopUserGoodsFetchingLoop();
        }

        const fetchData = async () => {
            const batchRequest = new (this.readWeb3().BatchRequest)();
            const promises = [
                this.fetchUserCryptoBalance(batchRequest),
                this.fetchUserPurchases(batchRequest),
                this.fetchUserInventoryInfo(batchRequest),
                this.fetchUserBalance(batchRequest),
                this.fetchMinerRewardsData(batchRequest),
            ];
            batchRequest.execute();
            await Promise.all(promises);
            const userExists = MetaInventoryContainer.sharedInstance().authUserData;
            if(!userExists) {
                this.destroyData();
                this.stopUserGoodsFetchingLoop();
            }
        };
        fetchData();
        this.fetchUserGoodsLoop = setInterval(() => {
            const userExists = MetaInventoryContainer.sharedInstance().authUserData;
            if(!userExists) {
                this.stopUserGoodsFetchingLoop();
            } else {
                fetchData();
            }
        }, 5000);
    }

    stopUserGoodsFetchingLoop () {
        clearInterval(this.fetchUserGoodsLoop);
        this.fetchUserGoodsLoop = null;
    }

    async fetchUserInventoryInfo (batchRequest = null) {

        try {
            const walletAddress = this.getWalletAddressForFetchInfo();
            let parcelInfo = {};
            let minerInfo = {};
            let dualMiners = {};
            if(walletAddress) {
                const promisesArray = [
                    this.fetchParcelInfo(walletAddress, batchRequest),
                    this.fetchMinerInfo(walletAddress, batchRequest),
                    // GlobalMakerService.$store.dispatch('finances/minerRewards/getBitopexDualMiners', { walletAddress }),
                    // GlobalMakerService.$store.dispatch('finances/minerRewards/getBitopexDualMiners', { walletAddress }),
                    GlobalMakerService.$store.dispatch('finances/minerRewards/getFilecoinDualMiners', { walletAddress }),
                ];
                const results = await Promise.all(promisesArray);
                parcelInfo = results[0];
                minerInfo = results[1];
                dualMiners = {
                    dualMinersActive: results[2].dualMinersActive,
                    dualMinersInactive: results[2].dualMinersInactive,
                    dualMinersTotal: results[2].dualMinersTotal,
                    activeDualMinersForAllMetaverses: results[2].activeDualMinersForAllMetaverses,
                    dualMinersHashpower: results[2].dualMinersHashpower,
                    dualMinersHashpowerForAllMetaverses: results[2].dualMinersHashpowerForAllMetaverses,
                };
            }

            MetaInventoryContainer.sharedInstance().userInventoryInfo.setData({
                ...parcelInfo,
                ...minerInfo,
                ...dualMiners,
            });
        } catch (e) {
            console.error('MetaWorldManager.js fetchUserInventoryInfo', e);
        }

    }

    async fetchMinerInfo (address, batchRequest = null) {
        let totalHash = null;
        let freeHash = null;
        if(batchRequest) {
            const { resolvedValues, promisesArray } = addAndPromisifyBatchCalls(batchRequest, [{
                method: this.contracts.Municipality.methods.minerInf(address).call,
                name: 'minerInfo',
            }]);
            await Promise.all(promisesArray);
            ({ totalHash, freeHash } = resolvedValues.minerInfo);
        } else {
            ({ totalHash, freeHash } = await this.contracts.Municipality.methods.minerInf(address).call());
        }

        const totalMinersCount = totalHash / MINER_HASH_POWER;
        const freeMinersCount = freeHash / MINER_HASH_POWER;
        return {
            totalMinersCount,
            freeMinersCount,
            activeMinersCount: totalMinersCount - freeMinersCount,
            totalHash,
            freeHash,
        };
    }

    async fetchParcelInfo (address, batchRequest = null, metaverseId = null) {
        let freeSlots = null;
        let totalParcelsCount = null;
        let upgradedParcelsCount = null;
        let claimedOnMap = null;

        if (!metaverseId) {
            metaverseId = this.metaverseId;
        }

        const municipalityContract = getParcelInfoContractByMetaverseId(metaverseId);

        if(batchRequest) {

            const { resolvedValues, promisesArray } = addAndPromisifyBatchCalls(batchRequest, [{
                method: municipalityContract.methods.parcelInf(address).call,
                // method: municipalityContract.methods.parcelInf(address).call, // TODO use Municiaplity contract from this.contracts after testnet redeployment
                name: 'parcelInfo',
            }]);
            await Promise.all(promisesArray);
            ({ freeSlots, parcelCount: totalParcelsCount, NumUpgraded: upgradedParcelsCount, claimedOnMap } = resolvedValues.parcelInfo);
        } else {
            ({ freeSlots, parcelCount: totalParcelsCount, NumUpgraded: upgradedParcelsCount, claimedOnMap } = await municipalityContract.methods.parcelInf(address).call());
        }

        const upgradedSlots = upgradedParcelsCount * PARCEL_UPGRADED_MINERS_COUNT;
        const totalSlots = (totalParcelsCount - upgradedParcelsCount) * PARCEL_MINERS_COUNT + upgradedSlots;

        return {
            totalParcelsCount,
            standardParcelsCount: totalParcelsCount - upgradedParcelsCount,
            upgradedParcelsCount,
            totalSlots,
            freeSlots,
            upgradedSlots,
            standardSlots: totalSlots - upgradedSlots,
            claimedOnMap,
        };
    }

    convertGYMNETtoUSD (val, tokenPrice) {
        return parseFloat(this.web3().utils.fromWei(val)) * tokenPrice;
    }

    async readMinerDataFromBlockchain (userWalletAddress = null, batchRequest = null) {
        const batchRequestInternal = batchRequest || new (this.web3().BatchRequest)();

        const requests = [];

        if(userWalletAddress) {
            const isSwitchedToGymnet = {method: this.contracts.MiningSC.methods.switchedToNewMinting(userWalletAddress).call, name: 'isSwitchedToGymnet'};
            requests.push(isSwitchedToGymnet);
        }
        const rewardPerBlockGYMNET = {method: this.contracts.MiningSC.methods.rewardPerBlock().call, name: 'rewardPerBlockGYMNET'};
        const totalMintedRewards = {method: this.contracts.MiningSC.methods.totalMintedTokens().call, name: 'totalMintedRewards'};
        const minerRewardsUtilityInfo = {method: this.contracts.MiningSC.methods.utilInfo(userWalletAddress).call, name: 'utilInfo'};
        const minerRewardsData = {method: this.contracts.GymAgregator.methods.getUserMinerRewards(userWalletAddress || NULLABLE_WALLET_ADDRESS).call, name: 'minerRewardsData'};
        requests.push(rewardPerBlockGYMNET, totalMintedRewards, minerRewardsData, minerRewardsUtilityInfo);

        const {resolvedValues: result, promisesArray} = addAndPromisifyBatchCalls(batchRequestInternal, requests);


        if(!batchRequest) {
            batchRequestInternal.execute();
        }

        await Promise.all(promisesArray);

        result.userTotalMiners = result.minerRewardsData[0];
        result.userTotalHashPower = result.minerRewardsData[1];
        result.totalClaims = result.minerRewardsData[2];
        result.totalPendingRewardsInGYMNETWEI = result.minerRewardsData[3];
        result.globalHashpower = result.minerRewardsData[4];
        result.userActiveMiners = Number(result.minerRewardsData[5]) / MINER_HASH_POWER;
        result.tokenPrice = result.minerRewardsData[6];
        return result;
    }

    async fetchMinerRewardsData (batchRequest = null) {
        let result = null;
        try {
            const userWalletAddress = this.getWalletAddressForFetchInfo();

            const resolvedValues = await this.readMinerDataFromBlockchain(userWalletAddress, batchRequest);

            MetaInventoryContainer.sharedInstance().setMinerRewardsData(null);

            let userTotalMiners = 0;
            let userActiveMiners = 0;
            let isSwitchedToGymnet = false;
            let totalClaims = 0;
            let totalPendingRewardsInGYMNETWEI = '0';
            let minerRewardsUtilityInfo = null;
            if(userWalletAddress) {
                userTotalMiners = parseFloat(resolvedValues.userTotalMiners);
                userActiveMiners = parseFloat(resolvedValues.userActiveMiners);
                isSwitchedToGymnet = resolvedValues.isSwitchedToGymnet;
                totalClaims = parseFloat(this.web3().utils.fromWei(resolvedValues.totalClaims));
                totalPendingRewardsInGYMNETWEI = resolvedValues.totalPendingRewardsInGYMNETWEI;
                minerRewardsUtilityInfo = resolvedValues.utilInfo;
                minerRewardsUtilityInfo.realAvailable = parseFloat(this.web3().utils.fromWei(minerRewardsUtilityInfo.available));
                minerRewardsUtilityInfo.available = parseFloat(this.web3().utils.fromWei(minerRewardsUtilityInfo.available)) + parseFloat(this.web3().utils.fromWei(totalPendingRewardsInGYMNETWEI));
                minerRewardsUtilityInfo.available = this.web3().utils.toWei(minerRewardsUtilityInfo.available.toString());
                minerRewardsUtilityInfo.total = parseFloat(this.web3().utils.fromWei(minerRewardsUtilityInfo.total)) + parseFloat(this.web3().utils.fromWei(totalPendingRewardsInGYMNETWEI));
                minerRewardsUtilityInfo.total = this.web3().utils.toWei(minerRewardsUtilityInfo.total.toString());
                minerRewardsUtilityInfo.usedBal = parseFloat(this.web3().utils.fromWei(minerRewardsUtilityInfo.usedBal));
                minerRewardsUtilityInfo.usedBal = this.web3().utils.toWei(minerRewardsUtilityInfo.usedBal.toString());
            }

            const rewardPerBlockGYMNET = parseFloat(this.web3().utils.fromWei(resolvedValues.rewardPerBlockGYMNET));
            const tokenPrice = parseFloat(this.web3().utils.fromWei(resolvedValues.tokenPrice));
            const rewardPerBlockUSD = this.convertGYMNETtoUSD(resolvedValues.rewardPerBlockGYMNET, tokenPrice);
            const totalMintedRewards = parseFloat(this.web3().utils.fromWei(resolvedValues.totalMintedRewards));
            const maxSupply = 270000000;
            // maxSupply = parseFloat(this.web3().utils.fromWei(await this.contracts.GymNetwork.methods.MAX_SUPPLY().call()));

            const dailyBlocks = 28800;
            const globalHashpower = parseFloat(resolvedValues.globalHashpower);
            const dailyRewards = rewardPerBlockUSD * dailyBlocks * userActiveMiners * MINER_HASH_POWER / globalHashpower;
            const yearlyRewards = 365 * dailyRewards;
            const roi = (userActiveMiners) ? (yearlyRewards * 100) / (userActiveMiners * MINER_UNIT_PRICE) : 0;

            result = {
                isSwitchedToGymnet,
                minerRewards: {
                    userMiners: {
                        userActiveMiners,
                        userTotalMiners,
                    },
                    myHashpower: userActiveMiners * MINER_HASH_POWER,
                    totalClaims,
                    totalPendingRewards: {
                        pendingMinerRewards: parseFloat(this.web3().utils.fromWei(totalPendingRewardsInGYMNETWEI)),
                        usdPrice: this.convertGYMNETtoUSD(totalPendingRewardsInGYMNETWEI === '0' ? '1' : totalPendingRewardsInGYMNETWEI, tokenPrice),
                    },
                    dailyRewards,
                    weeklyRewards: 7 * dailyRewards,
                    monthlyRewards: 30 * dailyRewards,
                    yearlyRewards,
                    yearlyRewardsToken: 365 * rewardPerBlockGYMNET * dailyBlocks * userActiveMiners * MINER_HASH_POWER / globalHashpower,
                    roi,
                    minerUnitPrice: MINER_UNIT_PRICE,
                    minerRewardsUtilityInfo,
                },
                globalStatistics: {
                    rewardPerBlockGYMNET,
                    globalHashpower,
                    userShares: (userActiveMiners * MINER_HASH_POWER * 100) / globalHashpower,
                    dailyGlobalRewards: rewardPerBlockGYMNET * dailyBlocks,
                    price: tokenPrice,
                    totalMinted: {
                        totalMintedRewards,
                        maxSupply,
                        totalMintedRewardsPercentage: 100 * totalMintedRewards / maxSupply,
                    },
                },
            };

        } catch (e) {
            console.error('MetaWorldManager.js fetchMinerRewardsData', e);
        }

        MetaInventoryContainer.sharedInstance().setMinerRewardsData(result);
    }

    async registerUMUser ({oldAddress, newAddress, partners, salt, signature}) {
        try {
            const userBalance = await this.contracts.USDT.methods.balanceOf(oldAddress).call();
            const weiBalance = this.web3().utils.fromWei(userBalance);
            const balance = parseFloat(weiBalance);
            const payValue = balance > 0 ? '160000000000000' : '0';
            await this.writeContracts.SignatureValidatorUM.methods.seedDataFromBack([[newAddress, oldAddress, partners, salt],signature]).send({
                value: payValue,
                from: newAddress,
            });
        } catch (e) {
            throw new Error("Something went wrong while interaction with blockchain");
        }
    }

    async claimAllRewards () {
        try {
            this.showLoading();
            const transactionAllowance = await this.assertWallet();

            if (!transactionAllowance.isAllowed) return;

            const readWeb3 = this.readWeb3();
            const gasPrice = await readWeb3.eth.getGasPrice();
            const tx = this.writeContracts.MiningSC.methods.claimAll();
            const estimatedGas = await tx.estimateGas({
                from: transactionAllowance.userWallet,
            });
            await tx.send({
                from: transactionAllowance.userWallet,
                gas: Math.floor(estimatedGas * GAS_FEE_MULTIPLIER),
                gasPrice,
            });

            PopupHelper.showSuccessNotification(TranslationHelper.translate('Transaction Success'));

            return true;
        } catch(e) {
            switch (e.code) {
                case WALLET_ERROR_CODES.USER_REJECTED:
                    throw new TxRejectedByUserException();
                case WALLET_ERROR_CODES.METAMASK_INTERNAL_ERROR:
                    PopupHelper.showInfoAlert(TranslationHelper.translate('Transaction in Process'), TranslationHelper.translate('Please wait for the ongoing transaction on the blockchain to complete before initiating a new one.'));
                    return;
                default:
                    throw e;
            }
        } finally {
            this.hideLoading();
        }
    }

    async validateIsCredentialExists ({email, wallet_address, username}) {
        let isExists;
        try {
            const isExistsRes = await ApiService.query('user/check-user-exists', {params: {email, wallet_address, username}});
            if (isExistsRes && isExistsRes.status === 200) {
                isExists = isExistsRes.data.exists;
            } else {
                throw new Error('Existing email');
            }
        } catch (e) {
            isExists = false;
            console.error('MetaWorldManager validateIsCredentialExists'); // TODO check error handling
        }

        return isExists;
    }

    async isUserHasPayment () {
        let canChange = false;
        try {
            ApiService.setHeader();
            const isUserHasPayment = await ApiService.get('user/can-change-related-wallet');
            if (isUserHasPayment && isUserHasPayment.status === 200) {
                canChange = !isUserHasPayment.data.can_change;
            } else {
                console.error('MetaWorldManager isUserHasPayment', isUserHasPayment); // TODO check error handling
            }
        } catch (e) {
            console.error('MetaWorldManager isUserHasPayment', e);
        }
        diagLog(this, {'isUserHasPayment': canChange});

        return canChange;
    }

    async getMunicipalityTransactionAllowanceInfo () {
        const authUser = MetaInventoryContainer.sharedInstance().authUserData;
        let currentWallet;
        if(!authUser.isFullyWeb2User) {
            try {
                currentWallet = await GlobalMakerService.$store.dispatch('auth/getCurrentWallet'); // TODO check getCurrentWallet function
            } catch (e) {
                console.warn('MetaWorldManager getMunicipalityTransactionAllowanceInfo pending user confirmation', e);
            }
        }
        const result = {
            isAllowed: authUser.isFullyWeb2User || authUser?.walletAddress && authUser.walletAddress?.toLowerCase() === currentWallet?.toLowerCase(),
            userWallet: authUser?.walletAddress,
            currentWallet,
        };
        diagLog(this, 'allowanceInfo' , result);
        return result;
    }

    async assertWallet () {
        const transactionAllowance = await this.getMunicipalityTransactionAllowanceInfo();
        if (!transactionAllowance.isAllowed) {
            if (transactionAllowance.currentWallet) {
                GlobalMakerService.$store.dispatch(
                    `affility/user/feedback/${ACTIONS_USER_FEEDBACK.SHOW_WRONG_WALLET_MODAL}`,
                    { show: true, address: transactionAllowance.currentWallet }
                );
            } else {
                GlobalMakerService.$store.dispatch(
                    `affility/user/feedback/modals/toast-notification/${TOAST_ACTIONS.SHOW_ERROR_TOAST}`,
                    {
                        description: TranslationHelper.translate('Your transaction has been declined. Please attempt the transaction again.'),
                    }
                )
            }
        }
        return transactionAllowance;
    }

    setWeb3Properties (web3, readWeb3, contracts, write, ethereum, chainId, address) {
        this.web3 = web3;
        this.readWeb3 = readWeb3;
        this.allContracts = contracts;
        this.allWriteContracts = write;
        this.ethereum = ethereum;
        this.chainId = chainId;
        this.address = address;
    }

    setMetaverseId (metaverseId) {
        this.metaverseId = metaverseId;
    }

    getWeb3Contract (contractName) {
        return this.contracts[contractName];
    }

    showLoading (title = null, message = null) {
        GlobalMakerService.$store.dispatch(`affility/user/feedback/modals/full-loader/${USER_FEEDBACK_ACTIONS.OPEN_FULL_PAGE_LOADING}`,
            title || 'Please Wait'
        );
    }

    hideLoading () {
        GlobalMakerService.$store.dispatch(`affility/user/feedback/modals/full-loader/${USER_FEEDBACK_ACTIONS.CLOSE_FULL_PAGE_LOADING}`);
    }

    async getBundlesDataFromContract(walletAddress) {
        let bundlesData = this.bundlesCache.get(this.metaverseId);
        if(!bundlesData) {
            bundlesData = await this.contracts.Municipality.methods.getBundles(walletAddress).call();
            this.bundlesCache.set(this.metaverseId, bundlesData);
        }
        return bundlesData;
    }

    destroyData () {
        MetaInventoryContainer.sharedInstance().destroyUserInventoryData();
        MetaNotificationsContainer.sharedInstance().emptyNotifications();
        MetaNotificationsContainer.sharedInstance().emptyNewNotifications();
    }
}

MetaWorldManager.instance = null;
