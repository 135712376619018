import { STATE } from "./names";
import { BannerProps as Banner } from "~/core/types/affility/components/composed/banner/BannerProps";

export default () => {
    return {
        [STATE.BANNERS]: [
            {
                id: 0,
                template: 'Template3',
                data: {
                    title: {
                        en: "BTC Super Bundles",
                        es: "BTC Super Bundles",
                        jp: "BTC Super Bundles",
                        ko: "BTC Super Bundles",
                        pt: "BTC Super Bundles",
                        ru: "BTC Super Bundles",
                        th: "BTC Super Bundles",
                        vn: "BTC Super Bundles",
                        zh: "BTC Super Bundles",
                    },
                    subtitle: {
                        en: "Product Update",
                        es: "Actualización del producto",
                        jp: "製品アップデート",
                        ko: "제품 업데이트",
                        pt: "Atualização do produto",
                        ru: "Обновление продукта",
                        th: "การอัปเดตผลิตภัณฑ์",
                        vn: "Cập nhật sản phẩm",
                        zh: "产品更新",
                    },
                    textsColor:"#252565",
                    actionText:"Buy now",
                    image: require('~/assets/affility/images/banner/super-bundles.png'),
                    background: `url(${require('~/assets/affility/images/banner/super-bundles-cover.png')})`,
                    href:"/shop#btc-bundles",
                },
            },
            {
                id: 1,
                template: 'Template1',
                data: {
                    title: {
                        en: "Upgraded Commission<br/>Structure",
                        es: "Comisión mejorada<br/>Estructura",
                        jp: "アップグレードされた手数料<br/>構造",
                        ko: "업그레이드된 위원회<br/>구조",
                        pt: "Estrutura<br/>de Comissão Atualizada",
                        ru: "Модернизированная структура<br/>Комиссии",
                        th: "โครงสร้างคอมมิชชันที่อัปเกรด",
                        vn: "Hoa hồng được nâng cấp<br/>Cơ cấu",
                        zh: "升級佣金<br/>架構",
                    },
                    subtitle: {
                        en: "More Gymnet Tokens!",
                        es: "¡Más tokens Gymnet!",
                        jp: "ジムネットトークンが増加！",
                        ko: "더 많은 Gymnet 토큰!",
                        pt: "Mais tokens da Gymnet!",
                        ru: "Больше токенов Gymnet!",
                        th: "โทเค็น Gymnet มากขึ้น!",
                        vn: "Nhiều token Gymnet hơn!",
                        zh: "更多 Gymnet 代幣！",
                    },
                    description: {
                        en: "<b>70%</b> Payout in USDT & <b>30%</b> Now Fully in GYMNET Tokens!",
                        es: "¡Pago del <b>70 %</b> en USDT y del <b>30 %</b> ahora completamente en tokens GYMNET!",
                        jp: "<b>70%</b> が USDT で支払われ、<b>30%</b> が GYMNET トークンで全額支払われるようになりました！",
                        ko: "<b>70%</b> USDT 지급 및 <b>30%</b> 이제 전액 GYMNET 토큰!",
                        pt: "<b>70%</b> Pagamento em USDT e <b>30%</b> Agora Totalmente em Tokens GYMNET!",
                        ru: "<b>70%</b> выплат в USDT и <b>30%</b> теперь полностью в токенах GYMNET!",
                        th: "การจ่ายเงิน <b>70%</b> เป็น USDT และ <b>30%</b> จ่ายเต็มจำนวนเป็นโทเค็น GYMNET แล้ว!",
                        vn: "<b>70%</b> Thanh toán bằng USDT & <b>30%</b> Hiện được thanh toán hoàn toàn bằng token GYMNET!",
                        zh: "<b>70%</b> 以 USDT 支付，<b>30%</b> 現在完全以 GYMNET 代幣支付！",
                    },
                    textsColor:"white",
                    image: require('~/assets/affility/images/banner/upgraded-structure.png'),
                    background: 'linear-gradient(90deg, #5D5CFD 0%, #DFDEFF 100%)',
                },
            },
            {
                id: 2,
                template: 'Template2',
                data: {
                    title: {
                        en: "Global Pool<br/>Now Open",
                        es: "Pool global<br/>Ya abierto",
                        jp: "グローバルプール<br/>オープンしました",
                        ko: "글로벌 풀<br/>이제 오픈했습니다",
                        pt: "Pool global<br/>já aberto",
                        ru: "Global Pool<br/>Теперь открыт",
                        th: "Global Pool<br/>เปิดแล้ว",
                        vn: "Global Pool<br/>Hiện đã mở",
                        zh: "全球池<br/>現已開放",
                    },
                    subtitle: {
                        en: "2% Generated From Total Turnover",
                        es: "2% Generado De La Facturación Total",
                        jp: "総売上高の2%",
                        ko: "총 매출에서 2% 생성",
                        pt: "2% gerado a partir do total da faturação",
                        ru: "2% от общего оборота",
                        th: "2% เกิดจากยอดขายรวม",
                        vn: "2% được tạo ra từ tổng doanh thu",
                        zh: "2% 源自總營業額",
                    },
                    textsColor:"white",
                    image: require('~/assets/affility/images/banner/global-pool.png'),
                    background: 'linear-gradient(90deg, #5D5CFD 0%, #DFDEFF 100%)',
                },
            },

            // {
            //     id: 3,
            //     template: 'Template3',
            //     data: {
            //         title: {
            //             en: "Upgraded Commission<br/>Structure",
            //             es: "Comisión mejorada<br/>Estructura",
            //             jp: "アップグレードされた手数料<br/>構造",
            //             ko: "업그레이드된 위원회<br/>구조",
            //             pt: "Estrutura<br/>de Comissão Atualizada",
            //             ru: "Модернизированная структура<br/>Комиссии",
            //             th: "โครงสร้างคอมมิชชันที่อัปเกรด",
            //             vn: "Hoa hồng được nâng cấp<br/>Cơ cấu",
            //             zh: "升級佣金<br/>架構",
            //         },
            //         subtitle: {
            //             en: "More Gymnet Tokens!",
            //             es: "¡Más tokens Gymnet!",
            //             jp: "ジムネットトークンが増加！",
            //             ko: "더 많은 Gymnet 토큰!",
            //             pt: "Mais tokens da Gymnet!",
            //             ru: "Больше токенов Gymnet!",
            //             th: "โทเค็น Gymnet มากขึ้น!",
            //             vn: "Nhiều token Gymnet hơn!",
            //             zh: "更多 Gymnet 代幣！",
            //         },
            //         textsColor:"white",
            //         actionText:"join_now",
            //         image: require('~/assets/affility/images/banner/upgraded-structure.png'),
            //         background: 'linear-gradient(90deg, #5D5CFD 0%, #DFDEFF 100%)',
            //         href:"/",
            //     },
            // },


        ] as Banner [],
    };
};
