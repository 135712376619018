import {
    BROADCAST_EVENT_TYPES,
    diagLog,
    ERROR_STATUSES,
    GYMSTREET_STATE,
    SUPPORTED_CHAIN_IDS,
    WALLET_ERROR_CODES
} from "~/core/services/utils/Constants";
import ApiService from "~/core/services/api-interaction/ApiService";
import {MetaUser} from "~/core/models/MetaUser";
import {constructSignatureMessage} from "~/core/helpers/WEB3Helper";
import JwtService from "~/core/services/JwtService";
import {MetaWorldManager} from "~/core/services/map/MetaWorldManager";
import {MetaInventoryContainer} from "~/core/models/MetaInventoryContainer";
import { GlobalMakerService } from "~/core/services/GlobalMakerService";
import { AUTH_FLOW_STEPS } from '~/core/services/utils/Constants';
import AuthService from "~/core/services/api-interaction/AuthService";
import AuthenticationError from "~/core/helpers/AuthenticationError";
import APIServiceError from "~/core/helpers/APIServiceError";
import SignatureDeclinedError from "~/core/helpers/SignatureDeclinedError";
import InvalidWalletAddressError from "~/core/helpers/InvalidWalletAddressError";
import { getChainById } from "~/constants/networks";
import {PopupHelper} from "~/core/helpers/PopupHelper";
import WalletService from "~/pages/finances/wallet/services/WalletService";
import {DataService} from "~/core/services/DataService";
import {USER_ALERT_TEXTS} from "~/constants/constants";
import {ACTIONS as TOAST_ACTIONS} from "~/store/affility/user/feedback/modals/toast-notification/names";
import {ACTIONS as AUTH_ACTIONS} from '~/store/affility/user/auth/names';

const state = () => ({
    applicationStatus: GYMSTREET_STATE.WALLET_NOT_CONNECTED,
    isInitWalletInProgress: false,
    isLoginInProgress: false,
    authUser: null,
    initData: {
        nonce: null,
        token: null
    },
    authCoreStep: AUTH_FLOW_STEPS.SIGN_UP_WITH_EMAIL,
    currentActiveAddress: null,
    isSendTxSuccess: false,
    activitiesData: {
          loading: false,
          items: [],
          meta: []
      },
    isSwapLoading: false,
    headerVisible: true,
    isBinaryAPIFailed: false,
    userAlert: {
        isVisible: false,
        alertText: '',
        alertIcon: require('@/assets/images/icons/time-out.svg'),
    }
});

const mutations = {
    SET_APPLICATION_STATUS(state, payload) {
        state.applicationStatus = payload;
    },
    SET_AUTH_USER(state, payload) {
        state.authUser = new MetaUser(payload);
    },
    UPDATE_AUTH_USER(state, payload) {
        if(state.authUser) {
            const isFromBroadcast = payload.broadcast;
            delete payload.broadcast;

            state.authUser.selfUpdate(payload);

            if(GlobalMakerService.$broadcast && !isFromBroadcast) {
                GlobalMakerService.$broadcast.postMessage({
                    type: BROADCAST_EVENT_TYPES.STORE_DISPATCH,
                    action: `affility/user/auth/${AUTH_ACTIONS.UPDATE_USER_AUTH_DATA}`,
                    payload,
                });
            }
        }
    },
    SET_INIT_DATA(state, initData) {
        for (const [key, value] of Object.entries(initData)) {
            state.initData[key] = value;
        }
    },
    LOG_OUT(state) {
        state.authUser = null;
        JwtService.destroyToken();
        JwtService.destroySignature();
        state.applicationStatus = GYMSTREET_STATE.WALLET_NOT_CONNECTED;
    },
    SET_AUTH_STEP(state, payload) {
        state.authCoreStep = payload;
    },
    SET_CURRENT_ACTIVE_ADDRESS(state, address) {
        state.currentActiveAddress = address;
    },
    SET_SEND_TX_SUCCESS(state, value) {
        state.isSendTxSuccess = value;
    },
    SET_SEND_TX_LOADING(state, value) {
        state.isSendTxLoading = value;
    },
    SET_ACTIVITIES_DATA(state, data) {
        state.activitiesData.items = data.activities;
        state.activitiesData.meta = data.meta;
    },
    SET_SWAP_LOADING(state, value) {
        state.isSwapLoading = value;
    },
    SET_HEADER_VISIBLE(state, visible) {
        state.headerVisible = visible;
    },
    SET_BINARY_API_STATUS (state, value) {
        state.isBinaryAPIFailed = value;
    },
    SET_USER_ALERT(state) {
        if (state.authUser?.userAlertType) {
            const text = USER_ALERT_TEXTS[state.authUser?.userAlertType];
            state.userAlert.isVisible = true;
            state.userAlert.alertText = text;
        } else {
            state.userAlert.isVisible = false;
            state.userAlert.alertText = '';
        }
    },
    CLEAR_USER_ALERT(state) {
        state.userAlert.isVisible = false;
        state.userAlert.alertText = '';
    },
}

const actions = {
    setBinaryAPIStatus ({ commit }, value) {
        commit('SET_BINARY_API_STATUS', value);
    },
    setCurrentActiveAddress({ commit}, payload) {
        commit('SET_CURRENT_ACTIVE_ADDRESS', payload);
    },
    async setupWalletAddress({dispatch}, payload) {
        let userWalletNonceRes = await ApiService.post('user/wallet-nonce', {address: payload.address});
        let signature = await dispatch('acceptSign', {
            address: payload.address,
            nonce: userWalletNonceRes.data.nonce
        });
        if (signature) {
            try {
                await ApiService.post('user/account/attach', {
                    sig: signature,
                    token: userWalletNonceRes.data.token,
                    provider: 'web3'
                });
            } catch (e) {
                const code = e?.code;
                if (code) {
                    switch (code) {
                        case ERROR_STATUSES.INVALID_USER_UPDATE:
                            throw new InvalidWalletAddressError('You have payments on your related wallet address. Please try to attach the same address as related wallet address.');
                        case ERROR_STATUSES.INVALID_TOKEN:
                            throw new InvalidWalletAddressError('Invalid signed token');
                        case ERROR_STATUSES.INVALID_REFERRER_CODE:
                            throw new InvalidWalletAddressError('Invalid referrer code');
                        case ERROR_STATUSES.INVALID_WALLET_SIGNATURE:
                            throw new InvalidWalletAddressError('Invalid signature');
                        case ERROR_STATUSES.EXISTING_WALLET:
                            throw new InvalidWalletAddressError('This wallet address is already associated with another user account. Please use another one!');
                    }
                }
                throw e;
            }
            await dispatch(`affility/user/auth/${AUTH_ACTIONS.UPDATE_USER_AUTH_DATA}`, {isVerified: true, walletAddress: payload.address, relatedWalletAddress: payload.address},{root:true});
        } else {
            throw new SignatureDeclinedError("User declined the signature");
        }
    },
    async handleRegisterUMUser({dispatch, commit, state}, {userNEWAddress}) {
        let userWalletNonceRes = await ApiService.post('user/wallet-nonce', {address: userNEWAddress}).catch(e => {
            return new Error('Failed to get nonce from server', e);
        });
        let signature = await dispatch('acceptSign', {
            address: userNEWAddress,
            nonce: userWalletNonceRes.data.nonce
        });
        if (signature) {
            let signUMUserRes = await AuthService.signUMUser(signature, userWalletNonceRes.data.token, userNEWAddress);
            let resData = signUMUserRes.data?.data;
            await MetaWorldManager.sharedInstance().registerUMUser({
                oldAddress: resData?.oldAddress,
                newAddress: resData?.newAddress,
                partners: resData?.partners,
                salt: resData?.salt,
                signature: resData?.signature,
            });
            await AuthService.completeRegisterUMUser();
            await dispatch(`affility/user/auth/${AUTH_ACTIONS.UPDATE_USER_AUTH_DATA}`, {
                walletAddress: userNEWAddress,
                relatedWalletAddress: userNEWAddress
            });
        } else {
            throw new SignatureDeclinedError("User declined the signature");
        }
    },
    async verifyChain({dispatch, commit, state}) {
        let currentChainId = await dispatch('getCurrentChainId', {});
        if (currentChainId && !SUPPORTED_CHAIN_IDS.includes(parseInt(currentChainId))) {
            const providerName = localStorage.getItem("provider") || 'injected';
            if (providerName) {
                await this.$setWeb3Provider(providerName);
            }
        }
    },
    async getCurrentChainId({commit, dispatch}) { // TODO check error handling and usages and refactor
        let currentChainId = null;
        try {
            currentChainId = await this.$web3().eth.getChainId();
        } catch (e) {
            console.error('Unknown error at auth.js getCurrentChainId', e);
        }

        return currentChainId;
    },
    async switchOrAddSupportedChain({commit, dispatch}, payload) {
        try {
            const currentChainId = await dispatch('getCurrentChainId', null);
            if(this.$config.chainId !== currentChainId) {
                await dispatch('openChainSwitchPopup');
                try {
                    const hexEncodedChainId = '0x' + Number(this.$config.chainId).toString(16);
                    await dispatch('requestToChangeChain', hexEncodedChainId);
                } catch(e) {
                    const chainIsNotAddedCode = 4902;
                    if(e?.code === chainIsNotAddedCode) {
                        const chain = getChainById(this.$config.chainId);
                        await dispatch('requestToAddChain', chain);
                    } else {
                        throw e;
                    }
                }
            }
        } catch (e) {
            throw e;
        }
    },
    async openChainSwitchPopup({commit, dispatch}, payload) {
        return new Promise((resolve, reject) => {
            dispatch('application/popup-control/showComfirmationPopup', {
                title: 'You need to switch to Binance Smart Chain',
                message: 'Switch to Binance Smart Chain to continue using GymStreet',
                type: 'danger',
                showIcon: true,
                buttons: [
                    {
                        title: 'Switch Chain',
                        type: 'primary',
                        callback: () => {
                            commit('application/popup-control/SET_COMFIRMATION_POPUP_DATA', null, {root: true});
                            resolve();
                        }
                    },
                    {
                        title: 'Cancel',
                        type: 'secondary',
                        callback: () => {
                            commit('application/popup-control/SET_COMFIRMATION_POPUP_DATA', null, {root: true});
                            reject();
                        }
                    }
                ]
            }, {root: true});
        });
    },
    async requestToChangeChain({commit, dispatch}, payload) {
        return await this.$ethereum().request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: typeof payload === 'number' ? '0x' + payload.toString(16): payload }],
        })
    },
    async requestToAddChain({commit, dispatch}, payload) {
        return await this.$ethereum().request({
            method: 'wallet_addEthereumChain',
            params: [{
                chainId: typeof payload.chainId === 'number' ? '0x' + payload.chainId.toString(16): payload.chainId,
                chainName: payload.name,
                nativeCurrency: payload.nativeCurrency,
                rpcUrls: [payload.url],
                blockExplorerUrls: [payload.blockExplorerUrl],
            }],
        })
    },
    async handleVerifyUserConnect({dispatch, commit, state}) {
        const address = await dispatch('auth/getCurrentWallet', {}, {root: true});
        await dispatch('verifyChain');
        if (state.authUser?.isUMUser && !state.authUser?.walletAddress) {
            await dispatch('handleRegisterUMUser', {userNEWAddress: address});
        } else {
            await dispatch('setupWalletAddress', {address});
        }
    },
    async initializeApp({commit, dispatch, state}, address) {
        if (!address) {
            throw new Error('Address is required');
        }
        const existingToken = JwtService.getToken();
        const lastLoginAddress = localStorage.getItem('lastLoginAddress');
        if(existingToken && lastLoginAddress?.toLocaleLowerCase() === address.toLocaleLowerCase()) {
            ApiService.setAuthorizationToken(existingToken);
            try {
                let authUserResponse = await dispatch('getAuthUser');
                await dispatch('setAuthUserData', authUserResponse.data.user);
                await dispatch('user/subscription/getFrozenAutoship', {}, {root: true});
            } catch (e) {
                localStorage.removeItem('lastLoginAddress');
                await dispatch("initWallet", {address});
            }
        } else {
            commit('SET_APPLICATION_STATUS', GYMSTREET_STATE.INIT_WALLET_IN_PROGRESS);
            await dispatch('initWallet', {address});
        }
        localStorage.setItem('lastLoginAddress', address);
    },
    async getAuthUser() {
        return await ApiService.get('user');
    },
    async login({commit, dispatch}, {signature, token}) {
        try {
            let loginResponse = await ApiService.post('auth/login', {
                token: token,
                sig: signature,
                local_time: new Date()
            });
            try {
                if (GlobalMakerService.$auth.loggedIn) {
                    await GlobalMakerService.$auth?.logout();
                }
            } catch (e) {  // TODO check error handling and usages and refactor
                console.error('Unknown error at login $auth logout failed ', e);
                throw e;
            }
            dispatch('changeAuthDataOfUser', {
                signature,
                jwtToken: loginResponse.data.token,
                apiToken: loginResponse.data.token,
                user: loginResponse.data.user
            });
            commit('SET_APPLICATION_STATUS', -1);
        } catch (e) {
            commit('SET_APPLICATION_STATUS', GYMSTREET_STATE.REQUEST_FAILED);
            await dispatch('logOut');
            throw e;
        }
    },
    async changeAuthDataOfUser({commit, dispatch}, payload) {
        const {signature, jwtToken, apiToken, user} = payload;
        if(signature) {
            JwtService.saveSignature(signature);
        }
        if(jwtToken) {
            JwtService.saveToken(jwtToken);
        }
        if(apiToken) {
            ApiService.setAuthorizationToken(apiToken);
        }
        if(user) {
            await dispatch('setAuthUserData', user);
            await dispatch('user/subscription/getFrozenAutoship', {}, { root: true });
        }
        const isFromBroadcast = payload.broadcast;
        if(GlobalMakerService.$broadcast && !isFromBroadcast) {
            GlobalMakerService.$broadcast.postMessage({
                type: BROADCAST_EVENT_TYPES.STORE_DISPATCH,
                action: 'application/driver/changeAuthDataOfUser',
                payload,
            });
        }
    },
    async initWallet({commit, dispatch}, {address, referralId, noRefCode, email, isGlobalTmsConfirmed, isAffiliateTmsConfirmed}) {
        let refId = this.$router.app.$route.query.ref || referralId;
        diagLog(this, 'refId = ', refId)
        let initWalletResponse;
        try {
            if (refId && !referralId) {
                diagLog(this, 'PAYLOAD IS => ', {address, referral_id: refId})
                initWalletResponse = await ApiService.post('init/user-web3', {address, referral_id: refId});
            } else {
                let payload = {address};
                if (noRefCode !== undefined) {
                    payload = {address, no_ref_code: noRefCode};
                } else if (referralId) {
                    payload.referral_id = referralId;
                }
                if (isGlobalTmsConfirmed !== undefined) {
                    payload.is_global_tms_confirmed = isGlobalTmsConfirmed;
                }
                if (isAffiliateTmsConfirmed !== undefined) {
                    payload.is_affiliate_tms_confirmed = isAffiliateTmsConfirmed;
                }
                if (email) {
                    payload.email = email;
                }
                diagLog(this, 'INIT WALLET PAYLOAD IS => ', payload)
                initWalletResponse = await ApiService.post('init/user-web3', payload);
            }
            commit('SET_INIT_DATA', initWalletResponse.data);
            if (initWalletResponse.data.user) {
                commit('SET_APPLICATION_STATUS', GYMSTREET_STATE.SIGNATURE_GRANT_IN_PROGRESS);
                let signature = await dispatch('acceptSign', {
                    address: address,
                    nonce: initWalletResponse.data.nonce
                });
                await dispatch('login', {signature: signature, token: initWalletResponse.data.token});
            } else {
                throw new AuthenticationError();
            }
        } catch (e) {
            commit('SET_APPLICATION_STATUS', -1);
            dispatch('clearWalletConnect');
            if (e instanceof APIServiceError && e.code) {
                if (e.code === ERROR_STATUSES.EXISTING_WALLET) {
                    throw new InvalidWalletAddressError('This wallet address is already associated with another user account. Please use another one!');
                } else if (e.code === ERROR_STATUSES.WALLET_NOT_FOUND ||
                           e.code === ERROR_STATUSES.INVALID_MLM_USER) {
                    throw new InvalidWalletAddressError('This wallet address is not associated with any user account. Please register first!');
                }
            }
            throw e;
        }
    },
    async clearWalletConnect() {
        const web3Provider = localStorage.getItem('provider');
        if (['walletconnect', 'walletconnectv1'].includes(web3Provider) && this.$ethereum?.()) {
            await this.$ethereum().disconnect();
        }
        localStorage.removeItem('walletconnect');
        localStorage.removeItem('provider');
    },
    setAuthUserData({ commit, dispatch, state }, payload) {
        commit('SET_AUTH_USER', payload);
        commit('SET_USER_ALERT');
        setTimeout(() => {
            dispatch('application/popup-control/onTwoFactorAuthenticationVisible',
                state?.authUser?.remindTwoFactorAuthentication || false,
            { root: true });
        }, 1000);
    },
    clearUserAlert({ commit }) {
        commit('CLEAR_USER_ALERT');
    },
    async acceptSign({}, payload) {
        let signMessage = constructSignatureMessage([
            'Welcome to GYMStreet!',
            'We need your signature to sign into the GymStreet platform.',
            'This request will not trigger a blockchain transaction or cost any gas fees.',
            'Your authentication status will reset after 24 hours.',
            'Wallet address:\n' + payload.address,
            'Nonce:\n' + payload.nonce,
        ]);
        let promise = null;
        const peerName = this.$ethereum().session?.peer?.metadata?.name?.toLowerCase();
        const peerUrl = this.$ethereum().session?.peer?.metadata?.url?.toLowerCase();
        const peerIsTrustwallet = peerName?.includes('trust wallet') || peerUrl?.includes('trustwallet');
        if(this.$ethereum().isWalletConnect && peerIsTrustwallet) {
            promise = this.$web3().eth.sign(
                signMessage,
                payload.address,
            );
        } else {
            promise = this.$web3().eth.personal.sign(
                signMessage,
                payload.address,
            );
        }

        return promise.catch((e) => {
            if (e && (e.code === WALLET_ERROR_CODES.USER_REJECTED ||
                      e.code === WALLET_ERROR_CODES.USER_DISAPPROVED_REQUESTED_METHOD ||
                      e.code === WALLET_ERROR_CODES.METAMASK_INTERNAL_ERROR ||
                      e.message.includes('User denied message signature') ||
                      e.message.includes('User canceled'))) {
                return Promise.reject(new SignatureDeclinedError("User declined the signature"))
            }
            return Promise.reject(e);
        });
    },
    async acceptAffiliateTOS({commit, dispatch, state}, {address}) {
        ApiService.setHeader();
        return await ApiService.post('auth/confirm-affiliate-tos', {});
    },
    async logOut({commit, dispatch}, payload) {
        const isFromBroadcast = payload?.broadcast;
        if(JwtService.getToken() && !isFromBroadcast) {
            ApiService.setHeader();
            await ApiService.post('auth/logout', {});
        }
        DataService.removeAllClaimStatusesFromLocalStorage();
        MetaWorldManager.sharedInstance().destroyData();
        MetaWorldManager.sharedInstance().stopUserGoodsFetchingLoop();
        MetaInventoryContainer.sharedInstance().setAuthUserData(null);
        MetaInventoryContainer.sharedInstance().userInventoryInfo.clear();
        await dispatch('auth/cleanAddresses', {}, {root: true});
        await dispatch('user/resetUserData', {}, {root: true});
        commit('CLEAR_USER_ALERT');
        commit('LOG_OUT');
        ApiService.deleteAuthorizationToken();
        dispatch('clearWalletConnect');

        if(GlobalMakerService.$broadcast && !isFromBroadcast) {
            GlobalMakerService.$broadcast.postMessage({
                type: BROADCAST_EVENT_TYPES.STORE_DISPATCH,
                action: 'application/driver/logOut',
                payload: {},
            });
        }
    },
    setAuthStep({commit}, step) {
        commit('SET_AUTH_STEP', step);
    },
    async userUpdate({ dispatch}, payload) {
        let updateResponse = await ApiService.post('user/update', payload);
        diagLog(this, 'UPDATE USER PAYLOAD IS => ', payload)
        let updateData = {};
        if (payload.email) {
            updateData.email = updateResponse.data.user.email;
        }
        if (payload.is_global_tms_confirmed === true) {
            updateData.isGlobalTmsConfirmed = true;
        }
        if (payload.is_affiliate_tms_confirmed === true) {
            updateData.isAffiliateTmsConfirmed = true;
        }
        await dispatch(`affility/user/auth/${AUTH_ACTIONS.UPDATE_USER_AUTH_DATA}`, updateData);
    },
    async setRelatedWalletAddress({dispatch, commit}, address) {
        ApiService.setHeader();
        await ApiService.post('user/set-related-wallet', {
            related_wallet_address: address
        });
        await dispatch(`affility/user/auth/${AUTH_ACTIONS.UPDATE_USER_AUTH_DATA}`, {relatedWalletAddress: address});
    },
    async claimMinerRewards() {
        try {
            const minerRewardsClaim = await ApiService.post(`claim/miner-rewards`);
            if (minerRewardsClaim.status === 200) {
                PopupHelper.showSuccessNotification(
                    'Transaction success',
                    'Your request is being processed. Please wait for a few minutes.',
                    true
                );
            }
            return true;
        } catch (e) {
            PopupHelper.showErrorAlert(
                'Transaction failed',
                'Oops... Something is went wrong'
            );
            return false;
        }
    },
    async checkAllowance({state,}, {tokenAddress, spenderAddress, allowancePrice}) {
        const address = state.authUser?.walletAddress;

        const ERC20_ABI = [
            {
                constant: true,
                inputs: [
                    {
                        name: "_owner",
                        type: "address",
                    },
                    {
                        name: "_spender",
                        type: "address",
                    },
                ],
                name: "allowance",
                outputs: [
                    {
                        name: "",
                        type: "uint256",
                    },
                ],
                payable: false,
                stateMutability: "view",
                type: "function",
            },
        ];

        const tokenContract = new (this.$readWeb3().eth.Contract)(ERC20_ABI, tokenAddress);

        const allowance = await tokenContract.methods.allowance(address, spenderAddress).call();

        return parseFloat(allowance) >= (allowancePrice ? parseFloat(allowancePrice) : 1e23);

    },
    async internalWalletSendTx({state, commit, rootState, dispatch}, payload) {
        commit('SET_SEND_TX_LOADING', true);
        try {
            let sendTx = await ApiService.post('internal-wallet/send-tx', payload);
            commit('SET_SEND_TX_SUCCESS', true);
            return true;
        } catch (error) {
            commit('SET_SEND_TX_SUCCESS', false);
            let errorText = error.message;
            if(error.message.includes('insufficient funds for gas * price + value')) {
                errorText = 'Insufficient funds for gas, please add BNB to your wallet';
            }

            dispatch(`affility/user/feedback/modals/toast-notification/${TOAST_ACTIONS.SHOW_ERROR_TOAST}`, {
                description: errorText,
            }, { root: true })
            return false;
        } finally {
            commit('SET_SEND_TX_LOADING', false);
        }


    },
    async getWalletActivities({commit}, {page, limit, filterBy}) {
        let activitiesData = null;
        try {
            activitiesData = await WalletService.getWalletTransactions(page, limit, filterBy);

            commit('SET_ACTIVITIES_DATA', activitiesData);
        } catch (error) {
            console.log('Activities error', error)
        }
        return activitiesData;
    },
    async confirmSwap({commit}, payload) {
        commit('SET_SWAP_LOADING', true);
        try {
            const swapData = await ApiService.post('/internal-wallet/swap', payload);

            console.log('swapData success', swapData)
            PopupHelper.showSuccessNotification(
                'Transaction success',
                'Transaction is successfully done'
            );
        } catch (error) {
            console.log('Swap error', error)
            let errorText = error.message;
            if(error.message.includes('insufficient funds for gas * price + value')) {
                errorText = 'Insufficient funds for gas, please add BNB to your wallet';
            }
            PopupHelper.showErrorAlert(errorText); // TODO Move popups from store to components
        } finally {
            commit('SET_SWAP_LOADING', false);
        }
    },
}

const getters = {
    applicationStatus: (state) => state.applicationStatus,
    authUser: (state) => state.authUser,
    isInitWalletInProgress: (state) => state.isInitWalletInProgress,
    isLoginInProgress: (state) => state.isLoginInProgress,
    authCoreStep: (state) => state.authCoreStep,
    currentActiveAddress: ({currentActiveAddress}) => currentActiveAddress,
    isSendTxSuccess: ({isSendTxSuccess}) => isSendTxSuccess,
    isSendTxLoading: ({isSendTxLoading}) => isSendTxLoading,
    activitiesData: ({activitiesData}) => activitiesData,
    isSwapLoading: ({isSwapLoading}) => isSwapLoading,
    headerVisible: (state) => state.headerVisible,
    isBinaryAPIFailed: (state) => state.isBinaryAPIFailed,
    userAlert: (state) => state.userAlert,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
