import { OrderCurrency } from "../constants/OrderCurrency";
import { OrderPaymentType } from "../constants/OrderPaymentMethod";
import { RawOrderHistoryEntry } from "../types/RawOrderHistoryEntry";
import {
    BTC_SINGLE_PRODUCT_NAMES,
    BUNDLE_NAME_AUTOSHIP,
    BUNDLE_NAMES,
    BUNDLE_NAMES_CARDS,
    BUNDLE_NAMES_OTHERS
} from "~/core/services/utils/Constants";
import { getClientRuntimeConfig } from "~/core/helpers/GlobalHelpers";
import {AffilitySuperBundlesIds, BTCSuperBundlesIds} from "~/store/affility/pages/shop/SuperBundleTypes";

const $config = getClientRuntimeConfig();

export class OrderHistoryEntry {
    public currencyAmountMap: Partial<Record<OrderCurrency, number>>;
    public bonusAmountMap: Partial<Record<OrderCurrency, number>>;
    public showCurrencies:Partial<Record<OrderCurrency, boolean>>;

    constructor (private rawData: RawOrderHistoryEntry) {
        this.currencyAmountMap = this.rawData.currencies?.reduce((acc, currency) => {
            acc[currency.currency] = currency.amount;
            return acc;
        }, {} as Partial<Record<OrderCurrency, number>>);
        this.bonusAmountMap = this.rawData.bonus?.reduce((acc, bonus) => {
            acc[bonus.currency] = bonus.amount;
            return acc;
        }, {} as Partial<Record<OrderCurrency, number>>);
        this.showCurrencies  = this.rawData.currencies?.reduce((acc,currency)=>{
            acc[currency.currency]  = true;
            return acc;
        },{} as Partial<Record<OrderCurrency, boolean>>);
    }

    public get txDate (): Date {
        return new Date(this.rawData.tx_date);
    }

    public get isAfterBtcDeployment() {
        const btcDeploymentDate = Number($config.btcDeploymentDate);
        const rowDate = new Date(this.rawData.tx_date).getTime();

        return rowDate > btcDeploymentDate;
    }

    public get product () {
        const nameMapsToCheck = [
            BUNDLE_NAMES,
            BUNDLE_NAMES_CARDS,
            BUNDLE_NAMES_OTHERS,
            BUNDLE_NAME_AUTOSHIP,
            BTC_SINGLE_PRODUCT_NAMES,
        ];

        const productId = this.rawData.product_id;
        const quantity = this.rawData?.quantity;

        const isQuantityRequired = Object.keys(BUNDLE_NAMES_OTHERS)
            .map(key => parseFloat(key))
            .includes(productId);

        let productName = nameMapsToCheck
            .map(nameMap => nameMap[productId])
            .find(name => !!name);

        const isSuperBundle = AffilitySuperBundlesIds.includes(productId) || BTCSuperBundlesIds.includes(productId);

        if (isSuperBundle) {
            if (this.isAfterBtcDeployment) {
                const isBTCSuperBundle = BTCSuperBundlesIds.includes(productId);
                const namePrefix = isBTCSuperBundle ? 'BTC ' : '';
                productName = `${namePrefix}${productName}`;
            }
        }

        if (!productName) return '';

        const quantityDisplay = (isQuantityRequired && quantity && quantity > 1)
            ? ` (${quantity})`
            : '';

        return productName + quantityDisplay;
    }

    public get status () {
        return this.rawData.status;
    }

    public get purchasedBy () {
        if(this.rawData.buyer.toLowerCase() === this.rawData.recipient_wallet_address.toLowerCase() ){

            return  this.rawData.buyer_username || this.rawData.recipient_username  || this.rawData.buyer_email || this.rawData.recipient_email || this.rawData.buyer  || this.rawData.recipient_wallet_address;
        }
        return  this.rawData.buyer_username  || this.rawData.buyer_email || this.rawData.buyer;

    }

    public get isAdmin() {
        return this.rawData.buyer.toLowerCase() === 'admin';
    }

    public get purchasedFor () {
        return this.rawData.recipient_username ||  this.rawData.recipient_email || this.rawData.recipient_wallet_address;
    }

    public get txId () {
        return this.rawData.tx_id;
    }

    public get paymentMethod () {
        return  OrderPaymentType[this.rawData.payment_method];
    }
}
